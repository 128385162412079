import bitmap from '../resources/font.png'
import { CANVAS_ID } from './gameconfig'

const fontMap = [
  [' ', 1, 1],
  ['!', 2, 1],

  ['*', 1, 2],
  ['+', 2, 2],
  ['-', 4, 2],
  ['.', 5, 2],
  ['/', 6, 2],

  ['A', 4, 4],
  ['B', 5, 4],
  ['C', 6, 4],
  ['D', 7, 4],
  ['E', 8, 4],
  ['F', 9, 4],
  ['G', 10, 4],

  ['H', 1, 5],
  ['I', 2, 5],
  ['J', 3, 5],
  ['K', 4, 5],
  ['L', 5, 5],
  ['M', 6, 5],
  ['N', 7, 5],
  ['O', 8, 5],
  ['P', 9, 5],
  ['Q', 10, 5],

  ['R', 1, 6],
  ['S', 2, 6],
  ['T', 3, 6],
  ['U', 4, 6],
  ['V', 5, 6],
  ['W', 6, 6],
  ['X', 7, 6],
  ['Y', 8, 6],
  ['Z', 9, 6],

  ['0', 7, 2],
  ['1', 8, 2],
  ['2', 9, 2],
  ['3', 10, 2],
  ['4', 1, 3],
  ['5', 2, 3],
  ['6', 3, 3],
  ['7', 4, 3],
  ['8', 5, 3],
  ['9', 6, 3]
]

const FONTCONFIG = {
  canId: CANVAS_ID,
  url: bitmap,
  zoom: 0.5,
  clearCanvas: false,
  map: {
    h: 42,
    w: 36,
    cols: 10,
    rows: 10,
    data: fontMap
  }
}

export { FONTCONFIG }
