/* eslint-env browser */

import { Howl } from 'howler'

/**
 * @typedef {Object} resourceDefinition
 * @property {string} id - unique identifier
 * @property {string} type - type of resource
 * @property {string} src - path to source(file)
 * @property {(HTMLImageElement|HTMLAudioElement)} [element] - the resource element
 * @property {Howl} [howl] - a Howler object
 */

const createHtmlElement = (resource, callback) => {
  let element = null
  if (resource.type === 1 || resource.type === 3) {
    element = new Audio()
    element.src = resource.src
  } else if (resource.type === 2) {
    element = new Image()
    if (typeof callback === 'function') {
      element.onload = callback
    }
    element.src = resource.src
  } else {
    throw new Error(`wrong type: ${resource.type}`)
  }
  return element
}

class ResourceManager {
  constructor () {
    this.cache = {}
    this.TYPE = {
      sound: 1,
      image: 2,
      howl: 3
    }
  }

  /**
   * @param {resourceDefinition} resource
   * @param {Function} callback
   */
  add (resource, callback) {
    if (!this.cache[resource.id]) {
      resource.element = createHtmlElement(resource, callback)
      if (resource.type === 3) {
        resource.howl = new Howl({ src: [resource.src] })
      }
      this.cache[resource.id] = resource
    }
    return this
  }

  /**
   * @param {string} id
   * @returns {resourceDefinition} the requested resource
   */
  get (id) {
    if (this.cache[id]) {
      return this.cache[id]
    } else {
      throw new Error(`Requested resource '${id}' not present!`)
    }
  }
}

export { ResourceManager }
