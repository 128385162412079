const c64charset = [
  ['A', 24, 60, 102, 126, 102, 102, 102, 0],
  ['0', 60, 102, 110, 118, 102, 102, 60, 0],
  ['1', 24, 24, 56, 24, 24, 24, 126, 0],
  ['2', 60, 102, 6, 12, 24, 48, 126, 0],
  ['3', 60, 102, 6, 28, 6, 102, 60, 0],
  ['4', 6, 14, 30, 102, 127, 6, 6, 0],
  ['5', 126, 96, 120, 6, 6, 102, 60, 0],
  ['6', 60, 102, 96, 124, 102, 102, 60, 0],
  ['7', 126, 102, 12, 24, 24, 24, 24, 0],
  ['8', 60, 102, 102, 60, 102, 102, 60, 0],
  ['9', 60, 102, 102, 62, 6, 102, 60, 0]
]

export { c64charset }
