/**
 * A simple timer with basic operations.
 * @example const mytimer = new Timer(2000); while (!mytimer.finished()) { // do cool stuff }
 */
class Timer {
  /**
   * @param {number} [i=0] interval (to be checked against)
   */
  constructor (i = 0) {
    /**
     * interval (to be checked against)
     * @type {number}
     * @since 0.4.7
     */
    this.i = i

    /**
     * starttime in ms
     * @private
     * @type {number}
     * @since 0.4.7
     */
    this._s = null
  };

  /**
   * Starts the timer.
   * @function
   * @since 0.4.7
   * @example if (something) { mytimer.start() }
   */
  start () {
    this._s = Date.now()
  };

  /**
   * Stops the timer. Finished() will now return 'true'.
   * @function
   * @since 0.4.7
   * @example mytimer.stop(); if (mytimer.finished()) { // do something }
   */
  stop () {
    this._s = 0
  }

  /**
   * Checks if configured interval is reached.
   * @function
   * @returns {boolean} FALSE - if {@link Timer#start} was NOT called before.
   * @returns {boolean} FALSE - if difference between start and now is less than interval.
   * @returns {boolean} TRUE - if {@link Timer#stop} was called.
   * @returns {boolean} TRUE - if defined interval was reached.
   * @since 0.4.7
   */
  finished () {
    return this.i > 0 && this._s !== null && Date.now() - this._s >= this.i
  };

  /**
   * Resets timer to actual Date.now() and sets new interval if it was given.
   * @method
   * @param {number} [ms] time in millis
   * @since 0.4.7
   * @example mytimer.reset()
   * @example mytimer.reset(5000)
   */
  reset (ms) {
    this.start()
    if (ms) {
      this.i = ms
    }
  };

  /**
   * Get the remaining time till interval is reached (in ms).
   * @function
   * @since 0.4.7
   * @returns {number} the remaining time in ms
   * @example remainingTimeDisplay.draw(mytimer.remain())
   */
  remain () {
    return this.finished() ? 0 : (this._s + this.i) - Date.now()
  };

  /**
   * Get the remaining time till interval is reached (as factor).
   * @function
   * @since 0.4.7
   * @returns {number} the remaining time as factor
   * @example coolDownProgressBar.actualLength = coolDownProgressBar.fullLength * mytimer.remainf()
   */
  remainf () {
    return this.remain() / this.i
  };

  /**
   * Get the elapsed time in relation to interval set (as factor).
   * @function
   * @since 0.5.2
   * @returns {number} the elapsed time as factor
   */
  fTimeElapsed () {
    return this.timeElapsed() / this.i
  }

  /**
   * Get the elapsed time since last start of timer
   * @function
   * @since 0.5.2
   * @returns {number} the elapsed time
   */
  timeElapsed () {
    return Date.now() - this._s
  }
}

export { Timer }
