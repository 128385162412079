/* eslint-env browser */

import titleSrc from '../resources/title.png'
import enemiesSrc from '../resources/enemys.png'
import playerSrc from '../resources/player.png'
import beamsSrc from '../resources/beams.png'
import astroidsSrc from '../resources/astroidsm.png'
import orbsSrc from '../resources/orbs.png'
import explosionSrc from '../resources/explosion64.png'

import sfxExplosionSrc from '../resources/sfx_crush_explode.ogg'
import sfxPlayerHitSrc from '../resources/ma_icepick_retrofuture.ogg'
import sfxPlayerSpawnSrc from '../resources/fx_blackhole.ogg'
import sfxParticleBeamSrc from '../resources/particlebeam.ogg'
import sfxSchwurbelstrahlSrc from '../resources/schwurwelstrahl.ogg'

import themeMusicSrc from '../resources/space_troopers_normalized.ogg'

export const preloadResources = (game) => {
  let imageCnt = 0

  const callback = () => {
    window.traft.progressor.oneLoaded()
  }

  const add = (id, type, src) => {
    game.resourceManager.add(
      {
        id: id,
        type: type,
        src: src
      },
      type === game.resourceManager.TYPE.image ? callback : null
    )

    if (type === game.resourceManager.TYPE.image) {
      imageCnt++
    }
  }

  add('title', 2, titleSrc)
  add('enemies', 2, enemiesSrc)
  add('player', 2, playerSrc)
  add('beams', 2, beamsSrc)
  add('astroids', 2, astroidsSrc)
  add('orbs', 2, orbsSrc)
  add('explosion', 2, explosionSrc)

  add('sfxex', 3, sfxExplosionSrc)
  add('sfxphit', 3, sfxPlayerHitSrc)
  add('sfxpspawn', 3, sfxPlayerSpawnSrc)
  add('sfxparticle', 3, sfxParticleBeamSrc)
  add('sfxschwurbel', 3, sfxSchwurbelstrahlSrc)

  add('theme', 3, themeMusicSrc)

  game.resourceManager.get('sfxparticle').howl.volume(0.3)
  game.resourceManager.get('sfxschwurbel').howl.volume(0.3)

  return imageCnt
}
