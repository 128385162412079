// # sourceMappingURL=schwurbler.min.js.map
/* eslint-env browser */
import { Starfield, DIRECTION as SFD } from 'traft-starfield'
import { Timer, values } from 'traft-core'
import { Font } from 'traft-pixelfont'

import { COMMANDS, STATES, CANVAS_ID, initGame } from './config/gameconfig'
import { GameObject } from './gameobjects/gameobject'
import { getShot, distributeBonuses, getWorldState } from './modules/utils'
import { TEXTS } from './config/texts'
import { FONTCONFIG } from './config/fontconfig'
import { createLevel } from './modules/levelmanager'
import { GAMEOBJECTS } from './config/gameobjectsconfig'
import { Hud } from './modules/hud'
import { Player } from './gameobjects/player'
import { preloadResources } from './config/preloaded'
import { createStartScreen } from './scenes/startscreen'

import { DIRECTION_RIGHT } from 'hammerjs'

// timing variables
let timeElapsed = 0
const ONE_SEC_BASE_TIMER = new Timer(1000)

// init FPS counting
const FPS = {
  display: 0,
  cycles: 0,
  started: Date.now()
}

// canvas and context
const CAN = document.getElementById(CANVAS_ID)
const CTX = CAN.getContext('2d')

// maximize playfield size
CAN.height = window.innerHeight <= window.innerWidth ? window.innerHeight : window.innerWidth
CAN.width = window.innerHeight <= window.innerWidth ? window.innerHeight : window.innerWidth

// init global container wich holds the state of the game, configuration & resources
const GAME = initGame(CAN.width, CAN.height)

// preload some resources and init traft.progressor to display their loading progress on screen
if (window.traft.progressor instanceof Object) {
  window.traft.progressor.init(
    CANVAS_ID,
    30,
    preloadResources(GAME))
} else {
  throw new Error('💩 progressor not present 💩')
}

// set game's title screen after resources are preloaded
GAME.startscreen = createStartScreen(GAME)

// set additional game resources
const STARFIELD_2D = new Starfield({
  canH: GAME.h,
  canW: GAME.w,
  numberStars: 500,
  starSize: 1,
  velocity: 60
})

const STARFIELD_3D = new Starfield({
  canH: GAME.h,
  canW: GAME.w,
  numberStars: 500,
  starSize: 1,
  velocity: 90,
  mode: '3d'
})

FONTCONFIG.zoom = FONTCONFIG.zoom * GAME.ratio
const FONT_RENDERER = new Font(FONTCONFIG)
const startscreenScrollerId = FONT_RENDERER.addScroller({ text: TEXTS.scroller.startscreen, x: 0, y: (GAME.h - 50 * GAME.ratio) | 0, step: 60, charsOnScr: 50 })

const HUD = new Hud(GAME, FONT_RENDERER)

const PLAYER = new Player(GAME, GAMEOBJECTS.player(GAME))
const SCHWURBEL = PLAYER.schwurberstrahl

// #################################################
// #         game controlls are going here         #
// #################################################

window.addEventListener('keydown', event => {
  switch (GAME.state) {
    case STATES.startscreen:
      if (event.code === 'Space') {
        startGame()
      }
      break
    case STATES.running:
      if (event.key === 'ArrowRight' && readyPlayer()) {
        GAME.currentCommand = COMMANDS.arcMoveCW
      }
      if (event.key === 'ArrowLeft' && readyPlayer()) {
        GAME.currentCommand = COMMANDS.arcMoveCounterCW
      }
      if (event.key === 'ArrowUp' && readyPlayer()) {
        GAME.previousCommand = GAME.currentCommand
        GAME.currentCommand = COMMANDS.activateSchwurbel
      }
      break
    default:
      break
  }
})

window.addEventListener('keyup', event => {
  switch (GAME.state) {
    case STATES.startscreen:
      if (event.code === 'KeyC') {
        toggleCredits()
      }
      break
    default: break
  }
})

window.Hammer = Hammer

const mcHammer = new window.Hammer.Manager(window.document.getElementById(CANVAS_ID))
const singleTap = new window.Hammer.Tap({ event: 'singletap' })
mcHammer.add([singleTap])
mcHammer.on('singletap', (evet) => {
  switch (GAME.state) {
    case STATES.startscreen:
      startGame()
      break
    case STATES.running:
      if (readyPlayer()) {
        GAME.currentCommand = GAME.currentCommand === COMMANDS.arcMoveCW ? COMMANDS.arcMoveCounterCW : COMMANDS.arcMoveCW
      }
      break
    default:
      break
  }
})

const hammerTime = new window.Hammer.Manager(window.document.body)
const swipeRight = new window.Hammer.Swipe({ event: 'swiperight', direction: DIRECTION_RIGHT })

hammerTime.add([swipeRight])
hammerTime.on('swiperight', () => {
  if (readyPlayer()) {
    GAME.previousCommand = GAME.currentCommand
    GAME.currentCommand = COMMANDS.activateSchwurbel
  }
})

// #################################################
// #             game events handling              #
// #################################################

const npcWasHit = (npc, collider) => {
  npc.energy -= collider.hitpoints
  if (npc.energy < 1) {
    npc.isAlive = false
    npc.wasKilled = true
    GAME.score += npc.score || 0
    createExplosion(npc)
    handelEmittedEvent(npc.event)
    if (npc.boss) {
      GAME.level.meta.bossDead = true
    }
  }
}

const playerWasHit = (p, collider) => {
  p.energy -= collider.hitpoints
  GAME.resourceManager.get('sfxphit').howl.play()
  if (p.energy < 1) {
    p.isAlive = false
    createExplosion(p)
    if (--GAME.lives > 0) {
      GAME.player.state = STATES.spawnNewShip
      GAME.currentCommand = null
      PLAYER.reset()
      GAME.resourceManager.get('sfxpspawn').howl.play()
    } else {
      GAME.state = STATES.gameOver
      setTimeout(resetGame, GAME.times.showLevelFinishedScr)
    }
  }
}

const environmentWasHit = (env, collider) => {
  // nothing to do at the moment
}

const enemyShotHasHit = (enemyShot) => {
  enemyShot.isAlive = false
}

const playerShotHasHit = (playerShot) => {
  playerShot.isAlive = false
}

/**
 * Handels an event or does nothing if event is null
 * @param {GameObjectEvent} event
 */
const handelEmittedEvent = (event) => {
  if (event) {
    switch (event.t) {
      case 'weapon':
        PLAYER.weapon = event.v
        GAME.resourceManager.get('sfxparticle').howl.play()
        break
      default:
        throw new Error(JSON.stringify({ message: '💩 EventHandler not implementend 💩', event: event }))
    }
  }
}

const fireEnemyShots = (level) => {
  level.visibleNpcObjects.filter(npc => npc.isAlive).forEach(npc => {
    if (npc.timeUp()) {
      npc.resetTimer()
      level.addEnemyShot(getShot(GAME, npc))
    }
  })
}

const firePlayerShots = () => {
  if (PLAYER.timeUp() && readyPlayer()) {
    PLAYER.resetTimer()
    GAME.level.addPlayerShot(getShot(GAME, PLAYER))
  }
}

const updateWorldObjects = (world, timeElapsed) => {
  for (const npc of world.visibleNpcObjects) {
    if (npc.isAlive) {
      npc.update(timeElapsed).animate()
      if (npc.energyBar) {
        npc.energyBar.update(npc, npc.maxEnergy)
      }
    }
  }
  updateAndAnimate(world.visibleEnvironmentObjects, timeElapsed)
  updateAndAnimate(world.visibleFxObjects, timeElapsed)
  updateAndAnimate(world.visibleEnemyShots, timeElapsed)
  updateAndAnimate(world.visiblePlayerShots, timeElapsed)
}

// #################################################
// #              scene handling                  #
// #################################################

const handleStartScreen = (timeElapsed) => {
  if (!GAME.themeMusicSid) {
    GAME.themeMusicSid = GAME.resourceManager.get('theme').howl.play()
  }
  STARFIELD_2D.moveLeft(timeElapsed).draw(CTX)
  updateWorldObjects(GAME.startscreen, timeElapsed)
  GAME.startscreen.update(timeElapsed).draw(CTX)
  FONT_RENDERER.scroll(startscreenScrollerId, timeElapsed)
}

const handleRunningGame = (timeElapsed) => {
  // update and animate gameobjects
  updateWorldObjects(GAME.level, timeElapsed)

  // trigger some events
  fireEnemyShots(GAME.level)
  firePlayerShots()

  // mandatory update for World
  GAME.level.update(timeElapsed)

  // trying only one collision detection per frame (World's default) to gain performance
  if (readyPlayer()) {
    // player vs environment
    if (GAME.level.environmentCollidesWith(PLAYER)) {
      environmentWasHit(GAME.level.environmentCollisionObject, PLAYER)
      playerWasHit(PLAYER, GAME.level.environmentCollisionObject)
    }
    // player vs NPC
    if (GAME.level.npcCollidesWith(PLAYER)) {
      npcWasHit(GAME.level.npcCollisionObject, PLAYER)
      playerWasHit(PLAYER, GAME.level.npcCollisionObject)
    }
    // enemy shots vs player
    if (GAME.level.enemyShotsCollidesWith(PLAYER)) {
      enemyShotHasHit(GAME.level.enemyShotCollisionObject)
      playerWasHit(PLAYER, GAME.level.enemyShotCollisionObject)
    }
    // player shots vs NPC
    if (GAME.level.playerShotCollidesWithNpc()) {
      playerShotHasHit(GAME.level.playerShotThatHit)
      npcWasHit(GAME.level.playerShotCollisionObject, GAME.level.playerShotThatHit)
    }
    // schwurbelstrahl vs NPC
    if (SCHWURBEL.isActive) {
      SCHWURBEL.particles.forEach(p => {
        if (GAME.level.npcCollidesWith(p)) {
          npcWasHit(GAME.level.npcCollisionObject, p)
        }
      })
    }
  } else if (PLAYER.marked) { // wait until new player ship is spawned and marked as 'ready'
    PLAYER.marked = false
    GAME.player.state = null
    GAME.resourceManager.get('sfxpspawn').howl.fade(1, 0, 250)
  }

  // after collision detection update player with it's bars & schwurbelstrahl
  PLAYER.update(timeElapsed)

  // get current state of the World and continue running level or prepare next level
  const worldState = getWorldState(GAME.level)

  if (!worldState.finished && !GAME.level.meta.bossDead) { // continue
    // check for bonuses
    const bonuses = distributeBonuses(GAME.level)
    for (const bonusInfo of bonuses) {
      GAME.score += bonusInfo.bonus
      HUD.addSplashText(JSON.stringify(bonusInfo.bonus))
    }

    // update & draw Starfield
    STARFIELD_3D.move(SFD.forwards, timeElapsed).draw(CTX)

    // draw World and it's vanilla Sprites
    GAME.level.drawBackground(CTX).draw(CTX)
    // draw GameObject's extensions
    for (const npc of GAME.level.visibleNpcObjects) {
      if (npc.isAlive && npc.energyBar) {
        npc.energyBar.draw(CTX)
      }
    }

    // draw player with it's bars & schwurbelstrahl
    PLAYER.draw(CTX)
  } else { // prepare next level
    GAME.score += worldState.bonus
    GAME.currentLevel++
    GAME.state = STATES.prepareNextLevel
    HUD.worldState = worldState
    stopMusic()
    setTimeout(setNewLevel, GAME.times.showLevelFinishedScr, false)
  }
}

const handleGameOver = (timeElapsed) => {
  STARFIELD_3D.move(SFD.forwards, timeElapsed).draw(CTX)
  FONT_RENDERER.write(TEXTS.gameOver, 0, 0, 'center')
}

const handleGameFinished = (timeElapsed) => {
  STARFIELD_3D.move(SFD.forwards, timeElapsed).draw(CTX)
  FONT_RENDERER.write(TEXTS.gameFinished, 0, 0, 'center')
}

const handlePrepareNextLevel = (timeElapsed) => {
  STARFIELD_3D.move(SFD.forwards, timeElapsed).draw(CTX)
}

// #################################################
// #                 flow control                 #
// #################################################

const handleUserInput = (timeElapsed) => {
  switch (GAME.currentCommand) {
    case COMMANDS.arcMoveCW:
      if (readyPlayer()) {
        PLAYER.arcMove(values.CLOCKW, values.STOP, timeElapsed)
      }
      break
    case COMMANDS.arcMoveCounterCW:
      if (readyPlayer()) {
        PLAYER.arcMove(values.COUNTER_CLOCKW, values.STOP, timeElapsed)
      }
      break
    case COMMANDS.activateSchwurbel:
      if (readyPlayer()) {
        SCHWURBEL.fireWhenNotCoolingDown()
        if (SCHWURBEL.isActive) {
          GAME.resourceManager.get('sfxschwurbel').howl.play()
        }
      }
      // restore previous command, otherwise player will stop movement
      GAME.currentCommand = GAME.previousCommand
      GAME.previousCommand = null
      break
    default:
      break
  }
}

const handleGameFlow = (state, timeElapsed) => {
  switch (state) {
    case STATES.loading:
      if (window.traft.progressor.finished()) {
        GAME.state = STATES.init
      }
      break
    case STATES.init:
      GAME.state = STATES.startscreen
      break
    case STATES.startscreen:
      handleStartScreen(timeElapsed)
      handleUserInput(timeElapsed)
      break
    case STATES.running:
      handleRunningGame(timeElapsed)
      handleUserInput(timeElapsed)
      break
    case STATES.prepareNextLevel:
      handlePrepareNextLevel(timeElapsed)
      break
    case STATES.gameOver:
      handleGameOver(timeElapsed)
      break
    case STATES.gameFinished:
      handleGameFinished(timeElapsed)
      break
    default:
      throw Error(`handleGameState: GAME.state '${GAME.state}' not handled!`)
  }
}

// #################################################
// #                  main loop                    #
// #################################################

const run = () => {
  // save elapsed time since last frame started (as factor to 1 second)
  timeElapsed = ONE_SEC_BASE_TIMER.fTimeElapsed()
  // remember starttime of this frame
  ONE_SEC_BASE_TIMER.reset()

  // FPS handling
  FPS.cycles++
  if (Date.now() - FPS.started >= 1000) {
    FPS.display = FPS.cycles
    FPS.cycles = 0
    FPS.started = Date.now()
  }

  if (GAME.state !== STATES.loading) {
    clearScreen('#000000')
  }

  // trigger game's logic
  handleGameFlow(GAME.state, timeElapsed)

  // draw the HUD
  if (GAME.state !== STATES.loading) {
    HUD.draw(CTX, GAME, PLAYER, FPS, timeElapsed)
  }

  // next frame please
  requestAnimationFrame(run)
}

// #################################################
// #                  start game                   #
// #################################################

ONE_SEC_BASE_TIMER.start()
run()

// #################################################
// #           some helper functions               #
// #################################################

const readyPlayer = () => {
  return GAME.player.state !== STATES.spawnNewShip
}

const clearScreen = (color) => {
  CTX.fillStyle = color
  CTX.clearRect(0, 0, CAN.width, CAN.height)
  CTX.fillRect(0, 0, CAN.width, CAN.height)
}

const createExplosion = (destroyedGameObject) => {
  const explosion = new GameObject(GAMEOBJECTS.fx.explosion.standard(GAME))
  explosion.matchCenter(destroyedGameObject)
  GAME.level.addFx(explosion)
  GAME.resourceManager.get('sfxex').howl.play()
}

const setNewLevel = () => {
  if (GAME.currentLevel > GAME.maxLevel) {
    GAME.state = STATES.gameFinished
  } else {
    GAME.level = createLevel(GAME.currentLevel, GAME)
    GAME.state = STATES.running
    startMusic()
  }
}

const stopMusic = () => {
  if (GAME.level.meta.music) {
    GAME.level.meta.music.fade(1.0, 0, 1500)
  }
}

const startMusic = () => {
  if (GAME.level.meta.music) {
    const soundId = GAME.level.meta.music.play()
    GAME.level.meta.music.volume(1.0, soundId)
    GAME.level.meta.music.loop(true, soundId)
  }
}

const updateAndAnimate = (objArr, timeElapsed) => {
  objArr.filter(obj => obj.isAlive).forEach(obj => obj.update(timeElapsed).animate())
}

const toggleCredits = () => {
  const el = document.getElementById('full-credits')
  if (!el.getAttribute('style')) {
    hideCredits()
  } else {
    document.getElementById('full-credits').removeAttribute('style')
  }
}

const hideCredits = () => {
  const el = document.getElementById('full-credits')
  el.setAttribute('style', 'display:none')
}

const startGame = () => {
  hideCredits()
  GAME.state = STATES.running
  GAME.currentLevel = 1
  setNewLevel()
  if (GAME.themeMusicSid) {
    GAME.resourceManager.get('theme').howl.stop(GAME.themeMusicSid)
    GAME.themeMusicSid = null
  }
}

const resetGame = () => {
  PLAYER.reset()
  stopMusic()
  GAME.lives = 3
  GAME.score = 0
  GAME.currentCommand = null
  GAME.previousCommand = null
  GAME.level = null
  GAME.player.state = null
  GAME.state = STATES.startscreen
}
