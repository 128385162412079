import { GameObject } from './gameobject'
import { EnergyBar } from '../modules/energybar'

class EndBossOne extends GameObject {
  constructor (config, game) {
    super(config)
    this.boss = true
    const ratioAdjustmentFactor = config.zoom * game.ratio
    this.energyBar = new EnergyBar({
      mode: 'bar',
      canW: game.w,
      canH: game.h,
      lineW: 32 * ratioAdjustmentFactor,
      len: config.w * ratioAdjustmentFactor,
      mar: -64 * ratioAdjustmentFactor,
      padX: 24 * ratioAdjustmentFactor,
      padY: 5 * ratioAdjustmentFactor,
      img: game.resourceManager.get('bossoneebar').element
    })
  }

  update (timeElapsed) {
    super.update(timeElapsed)
    if (super.autoFinished()) {
      this.ang += timeElapsed * 10
      this.rotate += timeElapsed * 10
      if (this.ang > 360) {
        this.ang = 0
      }
      if (this.rotate > 360) {
        this.rotate = 0
      }
    }
    return this
  }
}

export { EndBossOne }
