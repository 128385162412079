/**
 * @typedef {Object} vect2D
 * @global
 * @property {number} x x-coordinate
 * @property {number} y y-coordinate
 */

/**
 * @typedef {Object} AABBData
 * @global
 * @property {number} x x-coordinate
 * @property {number} y y-coordinate
 * @property {number} w width
 * @property {number} h height
 */

/**
 * @typedef {Object} AABBInfo
 * @global
 * @property {boolean} t object's top collides with candidate's bottom
 * @property {boolean} b object's bottom collides with candidate's top
 * @property {boolean} l object's left side collides with candidate's right side
 * @property {boolean} b object's right side collides with candidate's left side
 * @property {number} tdy absolute delta in pixels between object's top an candidate's bottom
 * @property {number} bdy absolute delta in pixels between object's bottom an candidate's top
 * @property {number} ldx absolute delta in pixels between object's left side an candidate's right side
 * @property {number} rdx absolute delta in pixels between object's right side an candidate's left side
 */

/**
 * @namespace
 */
class Utils {
  /**
  * Computes a valid degree depending on given start and distance to be moved.
  * @function
  * @static
  * @throws {Error} if returnvalue is < 0 or > 360
  * @param {number} angle - value to start (0 to 360)
  * @param {number} [speed=0] - moving speed of the object in pixels
  * @returns {number} A number between 0 and 360.
  * @since 0.1.0
  */
  static validateDegree (angle, speed = 0) {
    let a = angle + speed
    a = a > 360 ? a - 360 : (a < 0 ? a + 360 : a)
    if (a < 0 || a > 360) {
      throw new Error(`Result(${a}) for angle=${angle}, speed=${speed} is not valid!`)
    }
    return a
  };

  /**
   * Computes the radians for a given degree.
   * @function
   * @static
   * @param {number} a angle (0 to 360)
   * @returns {number} the radians for given angle
   * @since 0.1.0
   */
  static radians (a) {
    return a * (Math.PI / 180)
  }

  /**
   * Computes the x- and y-coordinate on a circle.<br>
   * The computed values will set object's center to given angle and radius.
   * @function
   * @static
   * @param {number} a angle in degree (0 to 360)
   * @param {number} r radius
   * @param {number} cx x-coordinate of circle's origin
   * @param {number} cy y-coordinate of circle's origin
   * @param {number} [w=0] object's width
   * @param {number} [h=0] object's height
   * @param {number} [z=1] object's zoom
   * @returns {Array<number>} [x, y] as integer
   * @since 0.1.0
   */
  static posOnArc (a, r, cx, cy, w = 0, h = 0, z = 1) {
    const rad = Utils.radians(a)
    return [
      Math.round((cx - w * z / 2) + r * Math.cos(rad)) | 0,
      Math.round((cy - h * z / 2) + r * Math.sin(rad)) | 0]
  };

  /**
   * calculate the vertices for wireframe
   * @function
   * @static
   * @param {number} x x-coordinate
   * @param {number} y y-coordinate
   * @param {number} w object's width
   * @param {number} h object's height
   * @param {number} [z=1] object's zoomfactor
   * @param {boolean} rotate rotates the vertices around center of object if true
   * @returns {Array<vect2D>}
   * @since 0.4.10
   */
  static computeHitbox (x, y, w, h, rotate, z = 1) {
    const v = new Array(4)
    v[0] = { x: x, y: y }
    v[1] = { x: x + w * z | 0, y: y }
    v[2] = { x: x + w * z | 0, y: y + h * z | 0 }
    v[3] = { x: x, y: y + h * z | 0 }
    if (!rotate) {
      return v
    } else {
      throw new Error('computeHitbox with rotation is not implemented yet')
    }
  };

  /**
   * Performs a simple AABB collision-detection
   * @function
   * @static
   * @param {AABBData} s object's data
   * @param {AABBData} c candidate's data
   * @since 0.5.0
   * @returns {boolean} true if rectangles intersect
   */
  static collideAABB (s, c) {
    return !(s.x > (c.x + c.w) || (s.x + s.w) < c.x || s.y > (c.y + c.h) || (s.y + s.h) < c.y)
  }

  /**
   * Performs a separated AABB collision-detection for top, bottom, left and right side
   * of an rectangle and calculates how much pixels the two objects overlap.
   * @function
   * @static
   * @param {AABBData} o object's data (considered as the moving object)
   * @param {AABBData} c candidate's data (considered as the static object)
   * @since 0.5.1
   * @returns {AABBInfo} the infos about how the object interacts with the candidate
   * @example const res = Utils.collideAABBInfos({ x: 0, y: 0, w: 20, h: 20 }, { x: 15, y: 10, w: 50, h: 50 }) // res.r = true; res.rdx = 5
   */
  static collideAABBInfos (o, c) {
    /*  # object
    *  - candidate
    *
    *           #####
    *     ------# b #-----
    *     -     #####    -
    *   #####           #####
    *   # r #           # l #
    *   #####           #####
    *     -     #####    -
    *     ------# t #-----
    *           #####
    */

    const oBottom = o.y + o.h
    const cBottom = c.y + c.h
    const oRight = o.x + o.w
    const cRight = c.x + c.w
    return {
      t: o.y <= cBottom && o.y >= c.y,
      tdy: Math.abs(cBottom - o.y) | 0,
      b: oBottom >= c.y && oBottom <= cBottom,
      bdy: Math.abs(c.y - oBottom) | 0,
      l: o.x <= cRight && o.x >= c.x,
      ldx: Math.abs(cRight - o.x) | 0,
      r: oRight >= c.x && oRight <= cRight,
      rdx: Math.abs(oRight - c.x) | 0
    }
  }
};

export { Utils }
