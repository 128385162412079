import { GAMEOBJECTS } from '../config/gameobjectsconfig'
import { EndBossOne } from '../gameobjects/endbossone'
import { GameObject } from '../gameobjects/gameobject'
import { addSquad } from '../modules/utils'

const createLevelThree = (world, game, resourcemanager) => {
  world.meta = {
    name: 'PHERKAD',
    bonus: 3000,
    squads: [],
    music: game.resourceManager.get('stlvl3').howl
  }

  world.background = game.resourceManager.get('bgrnebc').element
  world.backgroundStatic = true
  world.backgroundCentered = true

  world.addNpc(new EndBossOne(GAMEOBJECTS.enemy.bossOne(game), game))
  addSquad(game, world, 'r1', 2.5, 1, 6, 'RED ONE', 5000)
  addSquad(game, world, 'sat1', 7.5, 2, 3, 'SAT ONE', 500)
  world.addNpc(new GameObject(GAMEOBJECTS.item.orb.weaponTwo(game)), 10)
  addSquad(game, world, 'brnd', 15, 0.25, 200, 'BLUE RANDOM', 0)
  addSquad(game, world, 'astrnd', 20, 2.5, 5, 'ASTEROID RANDOM', 0)
  world.addNpc(new GameObject(GAMEOBJECTS.item.orb.weaponTwo(game)), 20)

  return world
}

export { createLevelThree }
