/**
 * @typedef {Object} point
 * @property {number} x
 * @property {number} y
 */

const getCharData = (char, charset) => {
  for (const data of charset) {
    if (data[0] === char) {
      return data
    }
  }
  return null
}

class Petski {
  constructor (charset) {
    this.charset = charset || []
    this.mode = 0
    this._mainColor = '#ffffff'
    this._bitLookUp = [128, 64, 32, 16, 8, 4, 2, 1]
  }

  /**
   * Renders a given text from left to right
   * @param {CanvasRenderingContext2D} ctx
   * @param {point} pos the position where to start rendering
   * @param {string} text
   * @param {number} [size=1] size of one pixel of font e.g. 1 => charsize: 8 x 8 px
   * @param {number} [alpha=1] transparency
   * @returns {Petski} self
   */
  writeText (text, pos, ctx, alpha = 1, size = 1) {
    // todo: line break
    for (let i = 0; i < text.length; i++) {
      this._writeChar(text[i], pos.x + size * i * 8, pos.y, ctx, alpha, size)
    }
    return this
  }

  /**
   * @private
   * @param {number} char
   * @param {number} x
   * @param {number} y
   * @param {CanvasRenderingContext2D} ctx
   * @param {number} alpha
   * @param {number} size
   */
  _writeChar (char, x, y, ctx, alpha, size) {
    const savedCtxAlpha = ctx.globalAlpha
    ctx.globalAlpha = alpha
    const chardata = getCharData(char, this.charset)
    if (chardata) {
      switch (this.mode) {
        case 1: // multicolor
          throw new Error('not implemented')
        case 2: // background
          throw new Error('not implemented')
        default: // unicolor
          ctx.fillStyle = this._mainColor
          for (let row = 1; row < 9; row++) { // skip identifier; start with 1
            const rowData = chardata[row]
            const rowPosY = y + row * size
            for (let b = 0; b < 8; b++) { // MSB for rendering is left
              if ((rowData & this._bitLookUp[b]) === this._bitLookUp[b]) {
                ctx.fillRect(x + b * size, rowPosY, size, size)
              }
            }
          }
      }
    } else {
      console.error('char not found: ' + char)
    }
    ctx.globalAlpha = savedCtxAlpha
  }

  /**
   * @see https://www.c64-wiki.de/wiki/POKE
   * @see https://www.c64-wiki.de/wiki/Grafikmodus
   * @param {number} address an C64 address
   * @param {number} value
   */
  poke (address, value) {
    throw new Error('not implemented')
  }

  /**
   * be warned: for performance sake the is no validation of input parameters
   * @param {number} canW width of canvas
   * @param {number} canH height of canvas
   * @param {number} size in px for one pixel of font e.g. 1 means 8 x 8 px
   * @param {string} text
   * @returns {point} the coordinates to writeText() a string at center of screen
   */
  getCenter (canW, canH, size, text) {
    return {
      x: canW / 2 - (8 * size * text.length) / 2,
      y: canH / 2 - (8 * size) / 2
    }
  }
}

export { Petski }
