import { GAMEOBJECTS } from '../config/gameobjectsconfig'
import { GameObject } from '../gameobjects/gameobject'
import { addSquad } from '../modules/utils'

import bossOneScr from '../resources/boss1.png'
import soundtrackSrc from '../resources/levelthree.ogg'
import energyBarSrc from '../resources/bossbarback.png'

const createLevelTwo = (world, game) => {
  // lazy load resources for level three
  game.resourceManager.add({
    id: 'bossone',
    type: game.resourceManager.TYPE.image,
    src: bossOneScr
  })
  game.resourceManager.add({
    id: 'stlvl3',
    type: game.resourceManager.TYPE.howl,
    src: soundtrackSrc
  })
  game.resourceManager.add({
    id: 'bossoneebar',
    type: game.resourceManager.TYPE.image,
    src: energyBarSrc
  })

  world.meta = {
    name: 'KOCHAB',
    bonus: 2000,
    squads: [],
    music: game.resourceManager.get('stlvl1').howl
  }

  world.background = game.resourceManager.get('bgrnebc').element
  world.backgroundStatic = true
  world.backgroundCentered = true

  addSquad(game, world, 's1', 0.5, 1, 6, 'SILVER ONE', 5000)
  world.addNpc(new GameObject(GAMEOBJECTS.item.orb.weaponTwo(game)), 0.5)
  addSquad(game, world, 'b4', 5, 0.5, 6, 'BLUE FOUR', 3000)
  addSquad(game, world, 'b3', 10, 0.5, 6, 'BLUE THREE', 4000)
  world.addNpc(new GameObject(GAMEOBJECTS.env.astroid.one(game)), 12)
  world.addNpc(new GameObject(GAMEOBJECTS.env.astroid.one(game)), 14)
  addSquad(game, world, 'r1', 15, 0.5, 8, 'RED ONE', 5000)

  return world
}

export { createLevelTwo }
