/* eslint-env browser */
import { values, events } from 'traft-core'
import { randomRange } from '../modules/utils'

// use shorten versions for convenience and size of transpiled script
const VAL = values
const ARC = VAL.AUTOTYPE.ARCMOVE
const COM = VAL.COMPASS
const SET = events.settings
const MOV = events.arcmove
const ALF = events.alpha
const MRK = events.mark

/**
 * The circle-playfield is devided in fixed rings.
 * A players's ring (p), four rings for NPCs (a-d)
 * and one out of view where NPCs went if they wheren't killed.
 * @param {Object} game - defined in gameconfig.js
 * @returns a circle's radius in px
 */
const RING = (game) => {
  const base = (game.h / 2) | 0
  return {
    p: (0.9 * base) | 0,
    a: (0.2 * base) | 0,
    b: (0.4 * base) | 0,
    c: (0.6 * base) | 0,
    d: (0.75 * base) | 0,
    out: (1 * base) | 0
  }
}

const getStartZoom = (desiredZoom, atGameRatio, targetRadius, startRadius) => {
  if (targetRadius > startRadius) {
    return ((desiredZoom * atGameRatio) / targetRadius) * startRadius
  } else {
    return desiredZoom * atGameRatio
  }
}

const GAMEOBJECTS = {
  base: {},
  sat: {},
  enemy: {},
  weapon: {
    enemy: {},
    player: {}
  },
  env: {
    astroid: {}
  },
  item: {
    orb: {}
  },
  fx: {
    explosion: {}
  }
}

// ##############
// ### PLAYER ###
// ##############

GAMEOBJECTS.player = (game) => {
  return {
    rad: RING(game).p,
    ang: COM.N,
    canW: game.w,
    canH: game.h,
    speed: 50 * game.speed.player,
    name: 'player',
    img: game.resourceManager.get('player').element,
    energy: 1000,
    hitpoints: 1000000,
    w: 113,
    h: 119,
    zoom: 0.25 * game.ratio,
    autoRotate: true,
    rotate: -180,
    auto: [
      SET.autoshot(400)
    ],
    weapon: 'laser',
    weaponFiringDir: values.TO_CENTER,
    type: 'p'
  }
}

// ###################
// ### BASEOBJECTS ###
// ###################

GAMEOBJECTS.base.satOne = (game) => {
  return {
    rad: RING(game).c,
    ang: COM.SW,
    canW: game.w,
    canH: game.h,
    speed: 90 * game.speed.npc,
    name: 'satOne',
    img: game.resourceManager.get('enemies').element,
    w: 242,
    h: 67,
    offx: 196,
    offy: 0,
    zoom: 0.20 * game.ratio,
    rotate: -180,
    autoRotate: true,
    energy: 500,
    hitpoints: 1000,
    score: 200,
    type: 'e'
  }
}

GAMEOBJECTS.base.blueShip = (game) => {
  return {
    rad: 1,
    ang: COM.NE,
    canW: game.w,
    canH: game.h,
    speed: 60 * game.speed.npc,
    energy: 100,
    hitpoints: 500,
    name: 'blueShip',
    img: game.resourceManager.get('enemies').element,
    w: 68,
    h: 100,
    zoom: getStartZoom(0.25, game.ratio, RING(game).d, 1),
    autoRotate: true,
    autoZoom: true,
    score: 75,
    type: 'e'
  }
}

GAMEOBJECTS.base.silverShip = (game) => {
  return {
    rad: RING(game).a,
    ang: COM.S,
    canW: game.w,
    canH: game.h,
    speed: 180 * game.speed.npc,
    name: 'silverShip',
    img: game.resourceManager.get('enemies').element,
    w: 115,
    h: 140,
    offx: 440,
    offy: 0,
    zoom: getStartZoom(0.3, game.ratio, RING(game).d, RING(game).a),
    autoZoom: true,
    autoRotate: true,
    alpha: 0,
    energy: 600,
    hitpoints: 250,
    score: 500,
    type: 'e'
  }
}

GAMEOBJECTS.base.weapon = (game) => {
  return {
    canW: game.w,
    canH: game.h,
    img: game.resourceManager.get('beams').element,
    autoRotate: true,
    rotate: 90,
    zoom: 0.75 * game.ratio,
    type: 'w'
  }
}

GAMEOBJECTS.base.astroid = (game) => {
  return {
    rad: 1,
    ang: 1,
    canW: game.w,
    canH: game.h,
    speed: 120 * game.speed.env,
    energy: 1000000,
    hitpoints: 1000000,
    name: 'astroid',
    img: game.resourceManager.get('astroids').element,
    w: 64,
    h: 64,
    autoRotate: true,
    autoZoom: true,
    zoom: (1 * game.ratio) / RING(game).p,
    ani: {
      repeat: true,
      die: false,
      ticks: 5,
      map: [[1, 1], [2, 1], [3, 1], [4, 1], [5, 1], [6, 1], [7, 1], [8, 1], [9, 1], [10, 1], [11, 1], [12, 1], [13, 1], [14, 1], [15, 1], [16, 1]]
    },
    type: 'env'
  }
}

GAMEOBJECTS.base.orbOne = (game) => {
  return {
    rad: 1,
    ang: 1,
    canW: game.w,
    canH: game.h,
    speed: 30 * game.speed.item,
    energy: 1,
    hitpoints: 500,
    name: 'orbOne',
    img: game.resourceManager.get('orbs').element,
    w: 40,
    h: 40,
    zoom: (1 * game.ratio) / RING(game).p,
    autoRotate: true,
    autoZoom: true,
    ani: {
      repeat: true,
      die: false,
      ticks: 1,
      map: [[1, 1], [2, 1], [3, 1], [4, 1], [5, 1], [6, 1]]
    },
    score: 100,
    type: 'i'
  }
}

GAMEOBJECTS.base.redShip = (game) => {
  return {
    rad: RING(game).d,
    ang: COM.SW,
    canW: game.w,
    canH: game.h,
    speed: 180 * game.speed.npc,
    energy: 400,
    score: 100,
    hitpoints: 750,
    name: 'redShip',
    img: game.resourceManager.get('enemies').element,
    w: 126,
    h: 105,
    offx: 70,
    offy: 0,
    zoom: getStartZoom(0.3, game.ratio, 1, RING(game).d),
    autoRotate: true,
    autoZoom: true,
    type: 'e'
  }
}

GAMEOBJECTS.base.explosion = (game) => {
  return {
    canW: game.w,
    canH: game.h,
    name: 'explosion',
    img: game.resourceManager.get('explosion').element,
    w: 64,
    h: 64,
    zoom: 1 * game.ratio,
    autoZoom: true,
    ani: {
      repeat: false,
      die: true,
      ticks: 1,
      map: [
        [1, 1], [2, 1], [3, 1], [4, 1], [5, 1], [6, 1], [7, 1], [8, 1],
        [1, 2], [2, 2], [3, 2], [4, 2], [5, 2], [6, 2], [7, 2], [8, 2],
        [1, 3], [2, 3], [3, 3], [4, 3], [5, 3], [6, 3], [7, 3], [8, 3],
        [1, 4], [2, 4], [3, 4], [4, 4], [5, 4], [6, 4], [7, 4], [8, 4],
        [1, 5], [2, 5], [3, 5], [4, 5], [5, 5], [6, 5], [7, 5], [8, 5],
        [1, 6], [2, 6], [3, 6], [4, 6], [5, 6], [6, 6], [7, 6], [8, 6],
        [1, 7], [2, 7], [3, 7], [4, 7], [5, 7], [6, 7], [7, 7], [8, 7],
        [1, 8], [2, 8], [3, 8], [4, 8], [5, 8], [6, 8], [7, 8], [8, 8]
      ]
    },
    type: 'fx'
  }
}

// ##############
// ### WEAPON ###
// ##############

GAMEOBJECTS.weapon.enemy.plasma = (game) => {
  const obj = GAMEOBJECTS.base.weapon(game)
  obj.hitpoints = 100
  obj.name = 'weapon.enemy.plasma'
  obj.w = 14
  obj.h = 16
  obj.offx = 6
  obj.offy = 125
  obj.speed = 240 * game.speed.npcShot
  return obj
}

GAMEOBJECTS.weapon.player.laser = (game) => {
  const obj = GAMEOBJECTS.base.weapon(game)
  obj.hitpoints = 100
  obj.name = 'laser'
  obj.w = 15
  obj.h = 35
  obj.offx = 207
  obj.offy = 165
  obj.speed = 120 * game.speed.playerShot
  return obj
}

GAMEOBJECTS.weapon.player.particleBeam = (game) => {
  const obj = GAMEOBJECTS.base.weapon(game)
  obj.hitpoints = 200
  obj.name = 'particlebeam'
  obj.w = 15
  obj.h = 30
  obj.offx = 40
  obj.offy = 55
  obj.speed = 120 * game.speed.playerShot
  return obj
}

// ################
// ### SATELITE ###
// ################

GAMEOBJECTS.sat.one = (game) => {
  const obj = GAMEOBJECTS.base.satOne(game)
  obj.alpha = 0.1
  obj.auto = [
    ALF.fadein(1, 0.01),
    SET.autoshot(500),
    MRK(),
    MOV.infiniteCircle(VAL.COUNTER_CLOCKW)
  ]
  obj.weapon = 'plasma'
  obj.weaponFiringDir = values.AWAY_FROM_CENTER
  return obj
}

// #################
// ### SPACESHIP ###
// #################

GAMEOBJECTS.enemy.blueOne = (game) => {
  const obj = GAMEOBJECTS.base.blueShip(game)
  obj.auto = [
    SET.initialize(),
    {
      t: ARC,
      rad: RING(game).c,
      dz: VAL.AWAY_FROM_CENTER
    },
    SET.rotate(90),
    {
      t: ARC,
      rad: RING(game).c,
      ang: COM.S,
      dxy: VAL.RIGHT
    },
    SET.rotate(0),
    {
      t: ARC,
      rad: RING(game).d,
      ang: COM.N,
      dz: VAL.AWAY_FROM_CENTER
    },
    SET.ready(),
    SET.rotate(270),
    MRK(),
    MOV.infiniteCircle(VAL.COUNTER_CLOCKW)
  ]
  return obj
}

GAMEOBJECTS.enemy.blueTwo = (game) => {
  const obj = GAMEOBJECTS.base.blueShip(game)
  obj.auto = [
    SET.initialize(),
    SET.ang(COM.NW),
    {
      t: ARC,
      rad: RING(game).c,
      dz: VAL.AWAY_FROM_CENTER
    },
    SET.rotate(270),
    {
      t: ARC,
      ang: 90,
      dxy: VAL.LEFT
    },
    SET.rotate(0),
    {
      t: ARC,
      rad: RING(game).d,
      dz: VAL.AWAY_FROM_CENTER
    },
    SET.ready(),
    SET.rotate(90),
    MRK(),
    MOV.infiniteCircle(VAL.CLOCKW)
  ]
  return obj
}

GAMEOBJECTS.enemy.blueThree = (game) => {
  const obj = GAMEOBJECTS.base.blueShip(game)
  obj.auto = [
    SET.initialize(),
    {
      t: ARC,
      rad: RING(game).c,
      ang: COM.NE,
      dz: VAL.AWAY_FROM_CENTER
    },
    SET.ready(),
    SET.autoshot(Math.round(500)),
    SET.rotate(90),
    MRK(),
    MOV.infiniteCircle(VAL.RIGHT)
  ]
  obj.weapon = 'plasma'
  obj.weaponFiringDir = values.AWAY_FROM_CENTER
  return obj
}

GAMEOBJECTS.enemy.blueFour = (game) => {
  const obj = GAMEOBJECTS.base.blueShip(game)
  obj.auto = [
    SET.initialize(),
    SET.ang(COM.NW),
    {
      t: ARC,
      rad: RING(game).c,
      ang: COM.NW,
      dz: VAL.AWAY_FROM_CENTER
    },
    SET.ready(),
    SET.rotate(270),
    MRK(),
    MOV.infiniteCircle(VAL.LEFT)
  ]
  obj.weapon = 'plasma'
  obj.weaponFiringDir = values.AWAY_FROM_CENTER
  return obj
}

GAMEOBJECTS.enemy.blueRandom = (game) => {
  const obj = GAMEOBJECTS.base.blueShip(game)
  obj.ang = randomRange(0, 359)
  obj.auto = [
    { t: ARC, rad: RING(game).out, dz: VAL.AWAY_FROM_CENTER },
    SET.die()
  ]
  return obj
}

GAMEOBJECTS.enemy.redOne = (game) => {
  const obj = GAMEOBJECTS.base.redShip(game)
  obj.auto = [
    SET.rotate(180),
    {
      t: ARC,
      rad: RING(game).c,
      ang: COM.SW,
      dz: VAL.TO_CENTER
    },
    {
      t: ARC,
      rad: RING(game).b,
      dxy: VAL.CLOCKW,
      dz: VAL.TO_CENTER
    },
    {
      t: ARC,
      rad: RING(game).a,
      dxy: VAL.COUNTER_CLOCKW,
      dz: VAL.TO_CENTER
    },
    {
      t: ARC,
      ang: COM.N,
      dxy: VAL.COUNTER_CLOCKW
    },
    SET.ang(COM.N + 2 * VAL.COUNTER_CLOCKW),
    {
      t: ARC,
      ang: COM.N,
      dxy: VAL.COUNTER_CLOCKW
    },
    SET.ang(COM.N + 2 * VAL.COUNTER_CLOCKW),
    {
      t: ARC,
      ang: COM.N,
      dxy: VAL.COUNTER_CLOCKW
    },
    SET.rotate(0),
    {
      t: ARC,
      rad: RING(game).p,
      dxy: VAL.COUNTER_CLOCKW,
      dz: VAL.AWAY_FROM_CENTER
    },
    SET.rotate(-20),
    {
      t: ARC,
      rad: RING(game).out,
      dxy: VAL.STOP,
      dz: VAL.AWAY_FROM_CENTER
    },
    SET.die(),
    MRK()
  ]
  return obj
}

GAMEOBJECTS.enemy.silverOne = (game) => {
  const obj = GAMEOBJECTS.base.silverShip(game)
  obj.auto = [
    {
      t: 'setting',
      rad: RING(game).a,
      ang: COM.W,
      rotate: 90
    },
    SET.initialize(),
    ALF.fadein(1, 0.05),
    {
      t: ARC,
      ang: 180,
      dxy: VAL.CLOCKW
    },
    SET.rotate(80),
    SET.rotate(70),
    SET.rotate(60),
    SET.rotate(50),
    SET.rotate(40),
    SET.rotate(30),
    SET.rotate(20),
    SET.rotate(10),
    SET.rotate(0),
    {
      t: ARC,
      rad: RING(game).c,
      dz: VAL.AWAY_FROM_CENTER
    },
    SET.ready(),
    SET.rotate(10),
    SET.rotate(20),
    SET.rotate(30),
    SET.rotate(40),
    SET.rotate(50),
    SET.rotate(60),
    SET.rotate(70),
    SET.rotate(80),
    SET.rotate(90),
    SET.autoshot(750),
    MRK(),
    MOV.infiniteCircle(VAL.CLOCKW)
  ]
  obj.weapon = 'plasma'
  obj.weaponFiringDir = values.AWAY_FROM_CENTER
  return obj
}

GAMEOBJECTS.enemy.bossOne = (game) => {
  const zoom = 0.7 * game.ratio
  return {
    w: 182,
    h: 248,
    zoom: zoom,
    alpha: 0,
    x: game.w / 2 - (182 * zoom) / 2 | 0,
    y: game.h / 2 - (248 * zoom) / 2 | 0,
    canW: game.w,
    canH: game.h,
    name: 'boss one',
    img: game.resourceManager.get('bossone').element,
    type: 'e',
    energy: 45000,
    score: 10000,
    collideable: false,
    auto: [
      ALF.fadein(1, 0.01),
      SET.rad(1),
      SET.autoshot(200),
      SET.collideable(),
      SET.ang(270),
      SET.rotate(5)
    ],
    weapon: 'plasma',
    weaponFiringDir: values.AWAY_FROM_CENTER
  }
}

// ###################
// ### ENVIRONMENT ###
// ###################

GAMEOBJECTS.env.astroid.one = (game) => {
  const obj = GAMEOBJECTS.base.astroid(game)
  obj.auto = [
    SET.ang(COM.N),
    {
      t: ARC,
      rad: RING(game).out,
      dz: VAL.AWAY_FROM_CENTER
    },
    SET.die(),
    MRK()
  ]
  return obj
}

GAMEOBJECTS.env.astroid.oneRandom = (game) => {
  const obj = GAMEOBJECTS.base.astroid(game)
  obj.ang = randomRange(0, 359)
  obj.auto = [
    { t: ARC, rad: RING(game).out, dz: VAL.AWAY_FROM_CENTER },
    SET.die(),
    MRK()
  ]
  return obj
}

// #############
// ### ITEMS ###
// #############

GAMEOBJECTS.item.orb.weaponTwo = (game) => {
  const obj = GAMEOBJECTS.base.orbOne(game)
  obj.event = { t: 'weapon', v: 'particlebeam' }
  obj.auto = [
    SET.ang(COM.S),
    {
      t: ARC,
      rad: RING(game).out,
      dz: VAL.AWAY_FROM_CENTER,
      dxy: VAL.COUNTER_CLOCKW
    },
    SET.die()
  ]
  return obj
}

// ##########
// ### FX ###
// ##########
GAMEOBJECTS.fx.explosion.standard = (game) => {
  return GAMEOBJECTS.base.explosion(game)
}

export { GAMEOBJECTS, RING }
