import { values, events } from 'traft-core'
import { GAMEOBJECTS, RING } from '../config/gameobjectsconfig'
import { GameObject } from '../gameobjects/gameobject'

/**
 * @param {GameObject} shooter the shooting object
 * @returns {GameObject} a shot based on shooter's current weapon
 */

const getShot = (game, shooter) => {
  const shot = new GameObject(getShotConfig(game, shooter))
  shot.matchCenter(shooter)
  return shot
}

/**
 * @param {GameObject} shooter the shooting object
 * @returns {Object} a configobject to construct a new GameObject()
 */
const getShotConfig = (game, shooter) => {
  let base = {}
  switch (shooter.weapon) {
    case 'plasma':
      base = GAMEOBJECTS.weapon.enemy.plasma(game)
      break
    case 'laser':
      base = GAMEOBJECTS.weapon.player.laser(game)
      break
    case 'particlebeam':
      base = GAMEOBJECTS.weapon.player.particleBeam(game)
      break
    default:
      throw new Error(`Gameobject ${shooter.name} -> property 'weapon' is not configured`)
  }

  const extensions = {
    autoZoom: true,
    x: shooter.x,
    y: shooter.y,
    rad: shooter.rad - (shooter.h * shooter.zoom),
    ang: shooter.ang,
    auto: [
      {
        t: values.AUTOTYPE.ARCMOVE,
        dz: shooter.weaponFiringDir,
        ang: shooter.ang,
        rad: shooter.type === 'e' ? RING(game).out : RING(game).a
      },
      events.settings.die()
    ]
  }
  return Object.assign(base, extensions)
}

/**
 * @typedef {Object} BonusInfo
 * @property {String} grp groupname
 * @property {number} bonus extra score
 * @example { grp: 'the a team', bonus: 42}
 */

/**
 * Checks if all members of a group where killed while they where approaching the game.
 * All NPCs will be considered, those wich are in viewport and those allread out of view or those not yet in view.
 * @param {World} world - a game's level
 * @returns {Array<BonusInfo>} empty if no bonus is to be distributed
 */
const distributeBonuses = (world) => {
  let numGrpObjects
  let numCandidates
  let numIgnored
  let hasOneEscaped
  const bonuses = []

  for (const squad of world.meta.squads) {
    if (!squad.bonusEarned && !squad.ignore) {
      numGrpObjects = 0
      numCandidates = 0
      numIgnored = 0
      hasOneEscaped = false

      for (const obj of world.npcObjects) {
        if (obj.grp === squad.grp) {
          numGrpObjects++
          if (obj.isInitializing && obj.wasKilled) {
            numCandidates++
          }
          if (!obj.isInitializing && obj.wasKilled) {
            hasOneEscaped = true
          }
          if (!obj.isAlive) {
            numIgnored++
          }
        }
      }

      if (numGrpObjects > 0 && numGrpObjects === numCandidates) {
        bonuses.push({ grp: squad.grp, bonus: squad.bonus })
        squad.bonusEarned = true
      } else if (hasOneEscaped) {
        squad.ignore = true
        squad.someEscaped = true
      } else if (numGrpObjects > 0 && numGrpObjects === numIgnored) {
        squad.ignore = true
      }
    }
  }
  return bonuses
}

/**
 * Adds a group of NPCs to a World (of traft-core)
 * @param {Object} game global game config
 * @param {World} world World wich houses the NPCs
 * @param {String} id of gameobject to be added (see getObject)
 * @param {number} start time in ms at wich first NPC of the group spawns
 * @param {number} interval time-diff to spawn next NPC
 * @param {number} count number of NPCs to spawn
 * @param {String} group name of the group
 * @param {number} bonus amount of bonus if group was killed while approaching
 * @example see ./config/levels/one.js
 */
const addSquad = (game, world, id, start, interval, count, group, bonus) => {
  for (let i = 0; i < count; i++) {
    const o = getObject(game, id)
    o.grp = group
    world.addNpc(o, start + interval * i)
  }
  world.meta.squads.push({
    grp: group,
    bonus: bonus,
    bonusEarned: false,
    ignore: false,
    someEscaped: false
  })
}

/**
 * @param {Object} game global game config
 * @param {String} id of Gameobject
 * @returns {GameObject}
 */
const getObject = (game, id) => {
  switch (id) {
    case 'b1':
      return new GameObject(GAMEOBJECTS.enemy.blueOne(game))
    case 'b2':
      return new GameObject(GAMEOBJECTS.enemy.blueTwo(game))
    case 'b3':
      return new GameObject(GAMEOBJECTS.enemy.blueThree(game))
    case 'b4':
      return new GameObject(GAMEOBJECTS.enemy.blueFour(game))
    case 'brnd':
      return new GameObject(GAMEOBJECTS.enemy.blueRandom(game))
    case 'r1':
      return new GameObject(GAMEOBJECTS.enemy.redOne(game))
    case 's1':
      return new GameObject(GAMEOBJECTS.enemy.silverOne(game))
    case 'sat1':
      return new GameObject(GAMEOBJECTS.sat.one(game))
    case 'astrnd':
      return new GameObject(GAMEOBJECTS.env.astroid.oneRandom(game))
    default:
      throw new Error(`configuration for object ${id} not present`)
  }
}

/**
 * @typedef {Object} worldStatus
 * @property {boolean} finished false if no enemy is present or at least 1 enemy is alive
 * @property {number} enemies total count of GameObject.type === 'e'
 * @property {number} killedEnemies total count of GameObject.wasKilled from enemies
 * @property {String} name world's name
 * @property {number} bonus achieved bonus; 0 if not all enemies where killed
 */

/**
 * Determines the status of a World.
 * @param {World} world
 * @returns {worldStatus}
 */
const getWorldState = (world) => {
  const enemies = world.npcObjects.filter(npc => npc.type === 'e')
  let enemiesKilled = 0
  let enemiesNonAlive = 0
  for (const enemy of enemies) {
    if (!enemy.isAlive) enemiesNonAlive++
    if (enemy.wasKilled) enemiesKilled++
  }
  return {
    finished: !!(enemies.length > 0 && enemiesNonAlive === enemies.length),
    enemies: enemies.length,
    killedEnemies: enemiesKilled,
    name: world.meta.name,
    bonus: enemiesKilled === enemies.length ? world.meta.bonus : 0
  }
}

const randomRange = (min, max) => {
  return Math.floor(Math.random() * (max - min - 1)) + min
}

export { getShot, getShotConfig, distributeBonuses, addSquad, getWorldState, randomRange }
