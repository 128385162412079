class EnergyBar {
  constructor ({
    mode = 'arc',
    img = null,
    len = 6,
    lineW = 8,
    backCol = 'rgb(75, 75, 75, 0.15)',
    frontCol = 'rgb(175, 25, 25, 1)',
    canW,
    canH,
    mar = 0,
    padX = 0,
    padY = 0
  }) {
    /**
     * possible valueB 'arc', 'bar'
     * @type {String}
     */
    this.mode = mode

    /**
     * additional image in mode 'bar'
     * @type {CanvasImageBource}
     */
    this.img = img

    /**
     * length of bar in degreeB (mode 'arc') or px (mode 'bar')
     * @type {number}
     */
    this.len = len

    /**
     * thickneBs of bar in px
     * @type {number}
     */
    this.lineW = lineW

    /**
     * background color
     * @type {String}
     */
    this.cB = backCol

    /**
     * front color
     * @type {String}
     */
    this.cF = frontCol

    /**
     * margin in px to attached object (y coordinate)
     */
    this.marY = mar

    /**
     * padding left & right in px (mode 'bar')
     */
    this.padX = padX

    /**
     * padding top & bottom in px (mode 'bar)
     */
    this.padY = padY

    /**
     * half of canvas' heigth
     * @type {number}
     * @private
     */
    this.hCanH = (canH / 2) | 0

    /**
     * half of canvas' width
     * @type {number}
     * @private
     */
    this.hCanW = (canW / 2) | 0

    /**
     * render data value object, filled via update() used in draw()
     * @private
     * @type {Object}
     * @property {number} sa start of arc (angle)
     * @property {number} sx start of bar x coordinate (px)
     * @property {number} sy start of bar y coordinate (px)
     * @property {number} eB end for background (x coordinate or angle)
     * @property {number} eF end for foreground (x coordinate or angle)
     * @property {number} r radius (px)
     */
    this.rd = {
      sa: 0,
      sx: 0,
      sy: 0,
      eB: 0,
      eF: 0,
      r: 0
    }

    if (!canH || !canW) {
      throw new Error('canW or canH not configured')
    }
  }

  /**
   * performs update of bar's values; mandatory call before drawing
   * @param {Sprite} baseObj the object to wich the EnergyBar is attached
   * @param {number} maxEnergy
   * @param {number} [customBaseEnergy] used instead of baseObj.energy
   * @returns self
   * @example myEnergyBar.update(myPlayerSprite, 1000).draw(myContext)
   * @example myEnergyBar.update(myPlayerSprite, 1000, 815).draw(myContext)
   */
  update (baseObj, maxEnergy, customBaseEnergy) {
    const t = this // reduce file size
    const rad = Math.PI / 180
    const energy = customBaseEnergy !== undefined ? customBaseEnergy : baseObj.energy
    const remainingEnergyFactor = energy / maxEnergy

    if (t.mode === 'bar') {
      t.rd.sx = baseObj.x + (baseObj.w * baseObj.zoom / 2) - t.len / 2 + t.padX
      t.rd.sy = baseObj.y + t.marY + t.padY
      t.rd.eB = t.len - 2 * t.padX
      /* remaining energy factor * length of bar
         - padding left (right shift there)
         - padding right
         padding has to be multiplied by remaining energy factor
         to ensure bar length end energy are zero at same time
      */
      t.rd.eF = remainingEnergyFactor * t.len - 2 * t.padX * remainingEnergyFactor
    } else {
      t.rd.sa = (baseObj.ang - t.len) * rad
      t.rd.eB = (baseObj.ang + t.len) * rad
      t.rd.eF = t.rd.sa + remainingEnergyFactor * t.len * 2 * rad
      t.rd.r = baseObj.rad + (baseObj.h + t.marY) * baseObj.zoom
    }
    return t
  }

  /**
   * draws the EnergyBar to the given canvas
   * @param {CanvasRenderingContext2D} ctx
   */
  draw (ctx) {
    const t = this // reduce file size
    ctx.lineWidth = t.lineW
    if (t.mode === 'bar') {
      t._bar(ctx, t.cB, t.rd.eB)
      t._bar(ctx, t.cF, t.rd.eF)
      if (t.img) {
        ctx.drawImage(
          t.img,
          t.rd.sx - t.padX,
          t.rd.sy - t.padY,
          t.len,
          t.lineW
        )
      }
    } else {
      t._arc(ctx, t.cB, t.rd.eB)
      t._arc(ctx, t.cF, t.rd.eF)
    }
  }

  /**
   * draws an arc to the given canvas
   * @private
   * @param {CanvasRenderingContext2D} ctx
   * @param {String} color
   * @param {number} end
   */
  _arc (ctx, color, end) {
    ctx.strokeStyle = color
    ctx.beginPath()
    ctx.arc(
      this.hCanW,
      this.hCanH,
      this.rd.r,
      this.rd.sa,
      end,
      false
    )
    ctx.stroke()
  }

  /**
 * draws an bar to the given canvas
 * @private
 * @param {CanvasRenderingContext2D} ctx
 * @param {String} color
 * @param {number} end length of line in px
 */
  _bar (ctx, color, end) {
    ctx.fillStyle = color
    ctx.fillRect(
      this.rd.sx,
      this.rd.sy,
      end > 0 ? end : 0,
      this.lineW - 2 * this.padY
    )
  }
}

export { EnergyBar }
