/**
 * configuration object for Starfield's constructor
 */
interface StarfieldConstructorData {
  /** height of canvas */
  canH: number
  /** width of canvas */
  canW: number
  /** number of stars */
  numberStars: number
  /** size of a star in px at projection reference point (of use Projection is true) else used as fixed value */

  mode: string
  
  starSize?: number
  /** switch for warpmode */
  warpmode?: boolean
  /** switch for usage of projection algorithm */
  useProjection?: boolean
  /** velocity of stars in px per second (if draw() is called with Time Elapsed set) else used as fixed value */
  velocity?: number
}

interface Vect3D {
  x: number
  y: number
  z: number
}

const DIRECTION = {
  left: 0,
  right: 1,
  up: 2,
  down: 3,
  forwards: 4,
  backwards: 5,
  stop: 6,
}

const randomRange = (min: number, max: number) => {
  return (Math.random() * (max - min - 1)) + min
}

/**
 * Provides a 'classic' starfield for the 2d canvas wich can be scrolled and zoomed.
 * 
 * Basic usage as **client library**
 * 
 * ```
 * <script src="../lib/traft-starfield.min.js"></script>
 * <script>
 *   const FIELD = new window.traft.starfield.instance(
 *     {
 *       canH: 640,
 *       canW: 480,
 *       numberStars: 500,
 *       starSize: 5,
 *       useProjection: true,
 *       velocity: 120
 *     }
 *   )
 * </script>
 * ```
 * 
 * Basic usage as **module**
 * 
 * ```
 * import { Starfield } from 'traft-starfield'
 * const FIELD = new Starfield({ ... your config goes here ... })
 * ```
 */
class Starfield {
  /**
   * height of canvas wich is also height of starfield
   * @private
   * @since 0.0.1
   */
  _canH: number
  /**
   * width of canvas wich is also width of starfield
   * @private
   * @since 0.0.1
   */
  _canW: number
  /**
   * the stars
   * @private
   */
  _stars: Array<Vect3D>
  /**
   * maximum value for z-coordinate
   * @private
   * @since 0.0.1
   */
  _maxDepth: number
  /**
   * observers z-position in 3d space
   * @private
   * @since 0.0.1
   */
  _projectionReferencePoint: number
  /**
   * extends the starfield at each side to smoothen the movement
   * @private
   * @since 0.0.1
   */
  _margin: number


  _canHH: number
  _canHW: number
  _mode: string

  /**
   * switch for warpmode
   * @since 0.0.1
   */
  warpmode: boolean
  /**
   * size of a star in px at projection reference point (of use Projection is true) else used as fixed value
   * @since 0.0.1
   */
  starSize: number
  /**
   * velocity of stars in px per second (if draw() is called with Time Elapsed set) else used as fixed value
   * @since 0.0.1
   */
  velocity: number
  /**
   * switch for usage of projection algorithm
   * @since 0.0.1
   */
  useProjection: boolean

  constructor(data: StarfieldConstructorData) {
    this._mode = data.mode ? data.mode : '2d'
    this.warpmode = data.warpmode ? data.warpmode : false
    this.useProjection = data.useProjection ? data.useProjection : false
    this.starSize = data.starSize ? data.starSize : 2
    this.velocity = data.velocity ? data.velocity : 60

    this._canH = data.canH
    this._canW = data.canW
    this._canHH = this._canH * 0.5
    this._canHW = this._canW * 0.5

    this._maxDepth = this._mode === '3d' ? (this._canHH + this._canHW) / 2 : 7500
    this._projectionReferencePoint = this._mode === '3d' ? this._maxDepth / 4 : (data.canW + data.canH) / 2
    this._stars = []
    this._margin = (data.canW + data.canH) / 8

    for (let i = 0; i < data.numberStars; i++) {
      if (this._mode === '3d') {
        // center of canvas is origin (0, 0)
        this._stars.push({
          x: randomRange(-this._canHW, this._canHW) | 0,
          y: randomRange(-this._canHH, this._canHH) | 0,
          z: randomRange(1, this._maxDepth)
        })
      } else {
        this._stars.push({
          x: this._getRandomPosition(data.canW),
          y: this._getRandomPosition(data.canH),
          z: (Math.random() * this._maxDepth) | 0,
        })
      }
    }
  }

  /**
   * @param velocity
   * @param [fTimeElapsed]
   * @since 0.0.1
   * @returns the distance travelled over time if fTimeElapsed was given, else the fixed velocity
   */
  private _getDistanceTraveled(
    velocity: number,
    fTimeElapsed?: number
  ): number {
    return fTimeElapsed ? fTimeElapsed * velocity : this.velocity
  }

  /**
   * Calculates the projected size of a star.
   * From {@link _maxDepth} to {@link _projectionReferencePoint} the size will be maximum {@link starSize}.
   * From {@link _projectionReferencePoint} the size will be between {@link starSize} and double of {@link starSize}.
   * For z-values less than 0 the size will be double of {@link starSize}.
   * @param z
   * @since 0.0.1
   * @returns the calculated projection size, but at least 1 px
   */
  private _getProjectedSize(z: number) {
    const relation = this._projectionReferencePoint / z
    const factor =
      relation > 1 ? 1 + z / this._projectionReferencePoint : relation
    const calculatedSize = (factor * this.starSize) | 0
    return z <= 0
      ? 2 * this.starSize
      : calculatedSize === 0
        ? 1
        : calculatedSize
  }

  /**
   * calculates a random position
   * @param base the basevalue e.g. the width of canvas for calculation x-coordinate
   * @since 0.0.1
   * @returns a random position depending on base and {@link _margin}
   */
  private _getRandomPosition(base: number): number {
    return (Math.random() * (base + this._margin) - this._margin / 2) | 0
  }

  /**
   * draws the starfield
   * @param ctx
   * @since 0.0.1
   * @returns self
   * @example myStarfield.draw(myContext)
   */
  draw(ctx: CanvasRenderingContext2D): Starfield {
    for (const star of this._stars) {
      ctx.fillStyle = '#ffffff'
      let size: number = this.useProjection
        ? this._getProjectedSize(star.z)
        : this.starSize
      if (this._mode === '3d') {
        const pos = this.project3D(star.x, star.y, star.z)
        ctx.fillRect(pos.x, pos.y, size, size)
      } else {
        ctx.fillRect(star.x, star.y, size, size)
      }
    }
    return this
  }

  /**
   * clears the given canvas
   * @param ctx
   * @param [color='#000000']
   * @since 0.0.1
   * @returns self
   * @example myStarfield.clearCanvas(myContext)
   * @example myStarfield.clearCanvas(myContext).draw(myContext)
   */
  clearCanvas(
    ctx: CanvasRenderingContext2D,
    color: string = '#000000'
  ): Starfield {
    ctx.fillStyle = color
    ctx.clearRect(0, 0, this._canW, this._canH)
    ctx.fillRect(0, 0, this._canW, this._canH)
    return this
  }

  /**
   * moves the starfield (but doesn't draw it)
   * @param direction {@link DIRECTION}
   * @param fTimeElapsed elapsed time since method was last called, calculated as factor from ms/1000
   * @since 0.0.1
   * @returns self
   * @example myStarfield.move(DIRECTION.left, 0.0167)
   * @example myStarfield.move(DIRECTION.up).clearCanvas(myContext).draw(myContext)
   */
  move(direction: number, fTimeElapsed?: number): Starfield {
    for (let star of this._stars) {
      const distanceTraveled = this._getDistanceTraveled(
        this.velocity,
        fTimeElapsed
      )

      if (this._mode === '3d') {
        switch (direction) {
          case DIRECTION.forwards:
            star.z -= distanceTraveled
            break
          case DIRECTION.backwards:
            star.z += distanceTraveled
            break
        }
        if (star.z > this._maxDepth || star.z < 0) {
          star.z = (Math.random() * this._maxDepth) | 0
        }
      }      
      else {
        const projectedSize = this._getProjectedSize(star.z)
        const randomMargin = Math.random() * this._margin
        switch (direction) {
          case DIRECTION.left:
            star.x -= distanceTraveled
            if (star.x < -projectedSize) {
              star.x = this._canW + randomMargin
              star.y = this._getRandomPosition(this._canH)
            }
            break
          case DIRECTION.right:
            star.x += distanceTraveled
            if (star.x > this._canW) {
              star.x = 0 - randomMargin
              star.y = this._getRandomPosition(this._canH)
            }
            break
          case DIRECTION.up:
            star.y -= distanceTraveled
            if (star.y < -projectedSize) {
              star.x = this._getRandomPosition(this._canW)
              star.y = this._canH + randomMargin
            }
            break
          case DIRECTION.down:
            star.y += distanceTraveled
            if (star.y > this._canW) {
              star.x = this._getRandomPosition(this._canW)
              star.y = 0 - randomMargin
            }
            break
        }
      }
    }
    return this
  }

  /**
   * calculate new coordinates in relation of current z-position to maxDepth
   * @param x 
   * @param y 
   * @param z 
   */
  project3D(x: number, y: number, z: number): Vect3D {
    return {
      x: (x * (this._maxDepth / z) + this._canHW) | 0,
      y: (y * (this._maxDepth / z) + this._canHH) | 0,
      z: z
    }
  }

  /**
   * @param fTimeElapsed see {@link move}
   * @since 0.0.1
   * @returns self
   */
  moveLeft(fTimeElapsed?: number): Starfield {
    this.move(DIRECTION.left, fTimeElapsed)
    return this
  }

  /**
   * @param fTimeElapsed see {@link move}
   * @since 0.0.1
   * @returns self
   */
  moveRight(fTimeElapsed?: number): Starfield {
    this.move(DIRECTION.right, fTimeElapsed)
    return this
  }

  /**
   * @param fTimeElapsed see {@link move}
   * @since 0.0.1
   * @returns self
   */
  moveUp(fTimeElapsed?: number): Starfield {
    this.move(DIRECTION.up, fTimeElapsed)
    return this
  }

  /**
   * @param fTimeElapsed see {@link move}
   * @since 0.0.1
   * @returns self
   */
  moveDown(fTimeElapsed?: number): Starfield {
    this.move(DIRECTION.up, fTimeElapsed)
    return this
  }
}

const version: string = '0.0.1'
const instance = Starfield
export { Starfield, version, instance, DIRECTION }
