/* eslint-env browser */

/**
 * @typedef {Array<String, number, number>} ImageMapCharDesc
 * @property {String} - [0] the char eg 'A'
 * @property {number} - [1] column
 * @property {number} - [2] row
 * @since 1.0.0
 */

/**
 * @typedef {Object} ImageMap
 * @property {number} h - height of character
 * @property {number} w - width of character
 * @property {number} rows - number of rows
 * @property {number} cols - number of cols
 * @property {ImageMapCharDesc} data - eg ['A', 1, 5] -> 'A' is found at col 1 and row 5
 * @since 1.0.0
 */

/**
 * @typedef {Array<String, number, number, number, number>} CharDataDefinition
 * @property {String} - [0] the char
 * @property {number} - [1] x-coordinate in map image
 * @property {number} - [2] y-coordinate in map image
 * @property {number} - [3] width
 * @property {number} - [4] height
 * @since 1.0.0
 */

/**
 * @typedef {Object} ScrollerDataConfig
 * @property {String} [text=''] - text to be displayed
 * @property {number} [x=1] - x-coordniate
 * @property {number} [y=1] - y-coordniate
 * @property {number} [step=1] - move x by Math.abs(step)
 * @property {number} [charsOnScr=1] number of chars to be displayed
 * @since 1.0.0
 */

/**
 * @typedef {Object} ContentData
 * @property {String} text - text to be drawn
 * @property {number} x - x-coordinate on canvas
 * @property {number} y - y-coordinate on canvas
 * @property {number} step - moving speed
 * @property {number} charsOnScr - number of chars at once on canvas
 * @property {String} textOnScr - text present on screen
 * @since 1.0.0
 */

class Font {
  /**
   * @param {Object} config
   * @param {String} config.canId - the id of the canvas to be used
   * @param {String} config.url - the url / filename of the image
   * @param {ImageMap} config.map - the descritor of the image
   * @param {number} [config.zoom=1] - zoomfactor
   * @param {boolean} [config.clearCanvas=true] - clear canvas before drawing?
   * @since 1.0.0
  */
  constructor ({ canId, url, map, zoom = 1, clearCanvas = true } = {}) {
    /**
     * content wich could be drawn; entry callable by it's id
     * @private
     * @type {Map<number, ContentData>}
     * @since 1.0.0
     */
    this.content = new Map()

    /**
     * zoomfactor
     * @private
     * @type {number}
     * @since 1.0.0
     */
    this.zoom = zoom

    /**
     * when true, canvas is cleared before drawing text
     * @private
     * @type {boolean}
     * @since 1.0.0
     */
    this.clearCanvas = clearCanvas

    const canvas = document.getElementById(canId)

    /**
     * context for rendering
     * @private
     * @type {CanvasRenderingContext2D}
     * @since 1.0.0
     */
    this.ctx = canvas ? canvas.getContext('2d') : null
    if (!this.ctx) {
      this.status = 'missing canvas'
      return
    }

    /**
     * height of used canvas
     * @private
     * @type {number}
     * @since 1.0.0
     */
    this.canvasH = canvas.height

    /**
     * width of used canvas
     * @private
     * @property {number}
     * @since 1.0.0
     */
    this.canvasW = canvas.width

    /**
     * the bitmap containing the chars
     * @private
     * @type {img}
     * @since 1.0.0
     */
    this.bitmap = new Image()
    if (!url) {
      this.status = 'missing url'
      return
    } else {
      this.bitmap.src = url
    }

    /**
     * the definition of chars ready to be drawn
     * @private
     * @type {Array<CharDataDefinition>}
     * @since 1.0.0
     */
    this.chars = []
    if (!map || !map.w || !map.h || !map.cols || !map.rows || !map.data || map.data.length === 0) {
      this.status = 'bad map'
      return
    } else {
      this.charW = map.w
      this.charH = map.h
      Array.from(map.data).forEach(e => {
        this.chars.push([e[0], (e[1] - 1) * map.w, (e[2] - 1) * map.h, map.w, map.h])
      })
    }

    /**
     * the status of the instance
     * @public
     * @type {String}
     * @since 1.0.0
     */
    this.status = this.chars.length === map.data.length ? 'OK' : 'bad map'
  }

  /**
   * Writes Text to the canvas.
   * @method
   * @public
   * @param {String} txt - text to be rendered
   * @param {number} [x=0] - x-coordinate
   * @param {number} [y=0] - y-coordinate
   * @param {String} [opt] - posible options 'centerx', 'centerx', 'center'
   * @returns {boolean} true if executed, false if unvalid txt given or status not 'OK'
   * @since 1.0.0
   */
  write (txt, x = 0, y = 0, opt = null) {
    if (this.status === 'OK' && typeof (txt) === 'string') {
      switch (opt) {
        case 'centerx':
          x = this.getCenterX(txt)
          break
        case 'centery':
          y = this.getCenterY()
          break
        case 'center':
          x = this.getCenterX(txt)
          y = this.getCenterY()
          break
      };
      if (this.clearCvs) { this.ctx.clearRect(0, 0, this.canvasW, this.canvasH) };
      for (let i = 0; i < txt.length; i++) {
        this._writeChar(txt[i], x, y)
        x += this.charW * this.zoom
      };
      return true
    };
    return false
  };

  /**
   * Add data for a scroller.
   * @public
   * @method
   * @param {ScrollerDataConfig} config - the config-object
   * @returns {number} a unique id (key)
   * @since 1.0.0
   */
  addScroller ({ text = '', x = 0, y = 0, step = 1, charsOnScr = 1 } = {}) {
    const obj = {
      text: typeof (text) === 'string' ? text : 'bad text',
      x: isNaN(x) ? 0 : parseInt(x),
      y: isNaN(y) ? 0 : parseInt(y),
      step: Math.abs(isNaN(y) ? 1 : parseInt(step)),
      charsOnScr: Math.abs(isNaN(y) ? 1 : parseInt(charsOnScr)),
      textOnScr: ''
    }
    return this._addContent(obj)
  }

  /**
   * Scrolls a text leftside by 'step' pixel and writes it on canvas.
   * If timeElapsed is used 'step' will be multiplied with it. Use it
   * if you want to get a more precise value for the distance covered per frame.
   * @public
   * @method
   * @param {number} id - a unique id (key)
   * @param {number} [timeElapsed] - elapsed time since last call as factor eg. 0.01667
   * @returns {boolean} true at success, false if content was not found
   * @since 1.0.0
   */
  scroll (id, timeElapsed = 1) {
    const s = this._getContent(id)
    if (s) {
      s.x -= s.step * timeElapsed
      if (s.x < -(this.charW * this.zoom)) {
        s.text = s.text.substring(1).concat(s.text[0])
        s.x += this.charW * this.zoom + 1
        s.textOnScr = s.text.substring(0, s.charsOnScr + 1)
      };
      return this.write(s.textOnScr, s.x, s.y)
    };
    return false
  };

  /**
   * @public
   * @method
   * @param {String} txt - some text eg 'Hannes'
   * @returns {number} the center-aligned x-coordinate if txt was valid, otherwise 0
   * @since 1.0.0
   */
  getCenterX (txt) {
    return typeof (txt) === 'string' ? this.canvasW / 2 - ((txt.length * this.charW * this.zoom) / 2) : 0
  };

  /**
   * @public
   * @method
   * @returns {number} the center-aligned y-coordinate considering the height of the char
   * @since 1.0.0
   */
  getCenterY () {
    return this.canvasH / 2 - this.charH / 2
  };

  /**
   * Writes a single char to canvas.
   * @private
   * @method
   * @param {String} char - a single character
   * @param {number} [x=0] - x-coordinate
   * @param {number} [y=0] - y-coordinate
   * @since 1.0.0
   */
  _writeChar (char, x = 0, y = 0) {
    const c = this._charData(char)
    if (c !== null) {
      this.ctx.drawImage(this.bitmap, c[1], c[2], c[3], c[4], x, y, this.charW * this.zoom, this.charH * this.zoom)
    };
  }

  /**
   * Retrieves the rendering-data for a single character.
   * @method
   * @private
   * @param {String} char - a single character
   * @returns {(CharacterData | null)} data for a char if found, null if not
   * @since 1.0.0
   */
  _charData (char) {
    for (let i = 0; i < this.chars.length; i++) {
      if (this.chars[i][0] === char) return this.chars[i]
    };
    return null
  };

  /**
   * Adds an entry to content.
   * @private
   * @method
   * @param {ContentData} scrollerData - The object to be added
   * @returns {number} a unique id (key)
   * @since 1.0.0
   */
  _addContent (scrollerData) {
    const id = `RF${Date.now() * Math.random()}`
    this.content.set(id, scrollerData)
    return id
  }

  /**
   * Retrieves the data of an desired object from content.
   * @private
   * @method
   * @param {number} id - a unique id (key)
   * @returns {(ContentData | null)} The Object's data if found, null if not
   * @since 1.0.0
   */
  _getContent (id) {
    const c = this.content.get(id)
    return c !== undefined ? c : null
  }
}

const version = '0.0.3'
const instance = Font
export { Font, version, instance }
