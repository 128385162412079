import { GameObject } from './gameobject'
import { EnergyBar } from '../modules/energybar'
import { Schwurbelstrahl } from '../gameobjects/schwurbelstrahl'
import { RING } from '../config/gameobjectsconfig'
import { events } from 'traft-core'

class Player extends GameObject {
  constructor (game, config) {
    super(config)

    this.energyBar = new EnergyBar({
      canW: game.w,
      canH: game.h,
      lineW: 8 * game.ratio
    })

    this.cooldownBar = new EnergyBar({
      canW: game.w,
      canH: game.h,
      lineW: 8 * game.ratio,
      mar: 32 * game.ratio,
      frontCol: 'rgb(0, 0, 200, 1)'
    })

    this.schwurberstrahl = new Schwurbelstrahl(
      this,
      RING(game),
      game.w,
      game.h,
      game.schwurbel.timer.firing,
      game.schwurbel.timer.cooldown,
      game.schwurbel.init.hitpoints)

    this.initConf = config
  }

  draw (ctx) {
    super.draw(ctx)
    this.energyBar.draw(ctx)
    this.cooldownBar.draw(ctx)
    if (this.schwurberstrahl.isActive) {
      this.schwurberstrahl.draw(ctx)
    }
  }

  update (timeElapsed) {
    super.update(timeElapsed)

    this.energyBar.update(
      this,
      this.initConf.energy)

    this.cooldownBar.update(
      this,
      this.schwurberstrahl.cooldownDuration,
      this.schwurberstrahl.cooldownDuration * this.schwurberstrahl.timerCooldown.remainf())

    this.schwurberstrahl.update(timeElapsed)
  }

  arcMove (dxy, dz, timeElapsed) {
    this.schwurberstrahl.arcMove(dxy, dz, timeElapsed)
    super.arcMove(dxy, dz, timeElapsed)
  }

  reset () {
    const t = this
    const conf = this.initConf

    t.isAlive = true
    t.energy = conf.energy
    t.ang = conf.ang
    t.alpha = 0.1
    t.weapon = conf.weapon

    t.auto = [events.alpha.fadein(1, 0.05, 3), events.mark()]
    super.restartAuto()
    super.arcMove(1) // hack to reset coordinates to players origin
    super.arcMove(-1)

    t.schwurberstrahl.realign(conf.ang)
  }
}

export { Player }
