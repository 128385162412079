/**
 * Some constants for convenience usage with Sprite.
 * @public
 * @namespace
 */
const values = {
  /** @memberof values */
  LEFT: -1,
  /** @memberof values */
  RIGHT: 1,
  /** @memberof values */
  UP: -1,
  /** @memberof values */
  DOWN: 1,
  /** @memberof values */
  CLOCKW: 1,
  /** @memberof values */
  COUNTER_CLOCKW: -1,
  /** @memberof values */
  TO_CENTER: -1,
  /** @memberof values */
  AWAY_FROM_CENTER: 1,
  /** @memberof values */
  ZOOM_IN: 1,
  /** @memberof values */
  ZOOM_OUT: -1,
  /** @memberof values */
  STOP: 0
}

/**
 * @namespace
 */
values.COMPASS = {
  /**
   * east, 0 / 360 degrees
   * @memberof values.COMPASS
   */
  E: 0,
  /**
   * south-east, 45 degrees
   * @memberof values.COMPASS
   */
  SE: 45,
  /**
   * south, 90 degrees
   * @memberof values.COMPASS
   */
  S: 90,
  /**
   * south-west, 135 degrees
   * @memberof values.COMPASS
   */
  SW: 135,
  /**
   * west, 180 degrees
   * @memberof values.COMPASS
   */
  W: 180,
  /**
   * north-west, 225 degrees
   * @memberof values.COMPASS
   */
  NW: 225,
  /**
   * north, 270 degrees
   * @memberof values.COMPASS
   */
  N: 270,
  /**
   * north-east, 315 degrees
   * @memberof values.COMPASS
   */
  NE: 315
}

/**
 * @namespace
 */
values.AUTOTYPE = {
  /**
   * use as type for configuring arcmove-automation
   * @memberof values.AUTOTYPE
   */
  ARCMOVE: 'arcmove',
  /**
   * use as type for configuring setting-automation
   * @memberof values.AUTOTYPE
   * @example const player = new Sprite({ auto: [{ t: values.AUTOTYPE.SETTING, alive: true }] });
   */
  SETTING: 'setting',
  /**
   * use as type for configuring alpha-automation
   * @memberof values.AUTOTYPE
   */
  ALPHA: 'alpha',
  /**
   * use as type for custom marker
   * @memberof values.AUTOTYPE
   */
  MARK: 'mark'
}

export { values }
