import { Sprite } from 'traft-core'

/**
 * @typedef {Object} GameObjectEvent
 * @property {String} t event type
 * @property {String} v value
 */

class GameObject extends Sprite {
  constructor (config) {
    super(config)
    this.hitpoints = config.hitpoints || 0
    this.score = config.score || 0
    this.energy = config.energy || 0
    this.maxEnergy = config.energy || 0
    this.weapon = config.weapon || null
    this.weaponFiringDir = config.weaponFiringDir || 0
    this.type = config.type || null
    this.wasKilled = false

    /**
     * displayable name
     * @type {string}
     */
    this.name = config.name || ''

    /**
     * identifier of a group where this Sprite is a member of
     * @type {string}
     */
    this.grp = config.grp || null

    /**
     * @type {EnergyBar}
     */
    this.energyBar = null

    /**
     * @type {EnergyBar}
     */
    this.cooldownBar = null

    /**
     * @type {GameObjectEvent}
     */
    this.event = config.event || null
  }
}

export { GameObject }
