import { TEXTS } from '../config/texts'
import { Sprite } from 'traft-core'
import { GAMEOBJECTS } from '../config/gameobjectsconfig'
import { STATES } from '../config/gameconfig'
import { Petski } from './petski'
import { c64charset } from '../config/c64charset'

class Hud {
  constructor (game, fontrenderer) {
    this.player = new Sprite(GAMEOBJECTS.player(game))
    this.player.alpha = 0.5
    this.player.rotate = 0

    this.fontrenderer = fontrenderer

    this.splashrenderer = new Petski(c64charset)
    this.splashSize = 3
    this.splashTimeElapsed = 0
    this.splashAlpha = 1
    this.splashText = ''

    this.worldState = null

    // pre-calculate some values to gain performance when drawing in main loop
    const charW = (fontrenderer.charW * fontrenderer.zoom) | 0
    const charH = (fontrenderer.charH * fontrenderer.zoom) | 0
    this.marginX = (charW / 2) | 0
    this.marginY = (charH / 2) | 0
    this.scoreX = this.marginX
    this.scoreY = this.marginY
    this.weaponX = this.scoreX
    this.weaponY = this.scoreY + charH
    this.fpsX = game.w - 7 * charW
    this.fpsY = this.scoreX
    this.versionX = game.w - (TEXTS.hud.version.length + 1) * charW
    this.versionY = this.weaponY
    this.playerY = game.h - this.player.h * this.player.zoom - this.marginY
    this.nxtLevRaY = (game.h / 2) - 3 * charH // 4 lines of text + 3 lines with blanks
    this.nxtLevRbY = (this.nxtLevRaY + 2 * charH) | 0
    this.nxtLevRcY = (this.nxtLevRbY + 2 * charH) | 0
    this.nxtLevRdY = (this.nxtLevRcY + 2 * charH) | 0
    this.nxtLevReY = (this.nxtLevRdY + 4 * charH) | 0
  }

  draw (ctx, game, player, fps, timeElapsed) {
    const FR = this.fontrenderer

    if (game.state === STATES.startscreen) {
      FR.write(TEXTS.start.rowa, 0, this.nxtLevRaY, 'centerx')
      FR.write(TEXTS.start.rowb, 0, this.nxtLevRbY, 'centerx')
      FR.write(TEXTS.start.rowc, 0, this.nxtLevRcY, 'centerx')
      FR.write(TEXTS.start.rowd, 0, this.nxtLevRdY, 'centerx')
      FR.write(TEXTS.start.rowe, 0, this.nxtLevReY, 'centerx')
    } else {
      FR.write(TEXTS.hud.fps + fps.display, this.fpsX, this.fpsY)
      FR.write(TEXTS.hud.version, this.versionX, this.versionY)
    }

    if (game.state === STATES.running) {
      FR.write(TEXTS.hud.score + game.score, this.scoreX, this.scoreY)
      FR.write(TEXTS.hud.weapon + player.weapon.toUpperCase(), this.weaponX, this.weaponY)
      for (let i = 0; i < game.lives; i++) {
        this.player.x = this.scoreX + i * this.player.w * this.player.zoom + this.marginX
        if (i > 0) {
          this.player.x += 10 * i // some extra space between
        }
        this.player.y = this.playerY
        this.player.draw(ctx)
      }
    }

    if (game.state === STATES.prepareNextLevel) {
      const ws = this.worldState
      FR.write(TEXTS.hud.stagePre + ws.name + TEXTS.hud.stageSuf, 0, this.nxtLevRaY, 'centerx')
      FR.write(TEXTS.hud.bodycount + ws.killedEnemies, 0, this.nxtLevRbY, 'centerx')
      FR.write(TEXTS.hud.ecaped + (ws.enemies - ws.killedEnemies), 0, this.nxtLevRcY, 'centerx')
      FR.write(TEXTS.hud.bonus + ws.bonus, 0, this.nxtLevRdY, 'centerx')
    }

    if (this.splashText && (this.splashTimeElapsed += timeElapsed) < game.times.splash) {
      this.splashSize += timeElapsed * 2
      if (this.splashAlpha > 0.1) {
        this.splashAlpha -= timeElapsed / 2
      }
      this.splashrenderer.writeText(
        this.splashText,
        this.splashrenderer.getCenter(game.w, game.h, this.splashSize, this.splashText),
        ctx,
        this.splashAlpha,
        this.splashSize)
    } else {
      this.splashText = ''
    }
  }

  addSplashText (text) {
    this.splashText = text
    this.splashAlpha = 1
    this.splashSize = 3
    this.splashTimeElapsed = 0
  }
}

export { Hud }
