/* eslint-env browser */

/**
 * @typedef {Object} AnimationConfig
 * @property { Array<Array<number, number>> } map tilemap
 * @property {boolean} repeat if true, animation is repeated infinite
 * @property {boolean} die if true, property 'isAlive' is set to false after last entry
 * @property {number} ticks new tile after 'ticks' is reached
 */

/**
 * @typedef {Array<ArcmoveConfig|SettingConfig|AlphaConfig>} AutomationConfig
 * @global
 * @example const s = new Sprite({ auto: [{ t: 'setting', approach: true }, {t: 'arcmove', ang: 181, dxy: -1}, { t: 'setting', approach: false }])
 */

/**
 * @typedef {Object} ArcmoveConfig
 * @global
 * @property {string} t set this to: 'arcmove'
 * @property {number} [ang] move till object reaches this angle
 * @property {number} [rad] move till object reaches this radius
 * @property {number} [dxy] xy-direction -1 (counter-clockwise) 1 (clockwise)
 * @property {number} [dz] z-direction -1 (to center) 1 (away from center)
 * @example { t: 'arcmove', ang: 181, dxy: -1 }
 */

/**
 * @typedef {Object} SettingConfig
 * @global
 * @property {string} t set this to: 'setting'
 * @property {boolean} approach if true, marks the object as currently approaching and not ready
 * @property {boolean} alive if true, marks the object as dead
 * @property {number} time time in millis
 * @example { t: 'setting', approach: true }
 * @example { t: 'setting', alive: false }
 * @example { t: 'setting', time: 250 }
 */

/**
 * @typedef {Object} AlphaConfig
 * @global
 * @property {string} t set this to: 'alpha'
 * @property {number} end desired value for alpha
 * @property {number} step value added to alpha
 * @property {number} [ticks] speed; more is slower; if none given alpha is increased every time update() is called
 * @example { t: 'alpha', end: 0.25, step: -0.01, ticks: 2 } // fade-out
 * @example { t: 'alpha', end: 1, step: 0.1 } // fade in
 */

/**
 * @typedef {Object} MarkerConfig
 * @global
 * @property {string} t set this to: 'mark'
 * @property {boolean} marked true / false
 */

import { Utils } from './utils'
import { values } from './values'

/**
 * @classdesc
 * A gameobject with integrated simple physics, animation and configurable automation.
 *
 * <p>You can use it standalone or as gameobjects in World-Class.</p>
 *
 * <p>Mandatory calls at each gameloop are {@link Sprite#update} and {@link Sprite#draw}.</p>
 *
 * @since 0.1.0
 */
class Sprite {
  /**
   * @constructor
   * @param {Object} config config object
   * @property {Image} img the image
   * @property {number} w Sprite's width
   * @property {number} h Sprite's height
   * @property {number} [offx=0] Sprite's x-position on image (if cropping is neccessary)
   * @property {number} [offy=0] Sprite's y-position on image (if cropping is neccessary)
   * @property {number} canW canvas' width
   * @property {number} canH canvas' height
   * @property {number} [x=0] x-coordinate
   * @property {number} [y=0] y-coordinate
   * @property {number} [ang = 0] angle in degree (0 - 360)
   * @property {number} [rad = 1] radius in px; minimum is 1
   * @property {number} [zoom=1.0] zoomfactor
   * @property {boolean} [autoZoom=false] if true, {@link Sprite#zoom} will be adapted to {@link Sprite#rad} when {@link Sprite#arcMove} is called
   * @property {number} [rotate=0] rotation in degree (0 - 360)
   * @property {boolean} [autoRotate=false] if true, {@link Sprite#rotate} will be adapted to {@link Sprite#ang} when {@link Sprite#draw} is called
   * @property {number} [alpha=1.0] transparency (0 - 1.0)
   * @property {number} [speed=0] Sprite's velocity
   * @property {AnimationConfig} [ani=null] animation to play when {@link Sprite#update} or {@link Sprite#animate} is called
   * @property {AutomationConfig} [auto=null] events to process when {@link Sprite#update} is called
   * @property {boolean} [collidable=true] marks Sprite as collideable
   * @property {Object} [meta={}] container for custom meta data
   * @example A Sprite wich moves automatically in circles and plays some animation.
   *
   * const mySprite = new Sprite({
   *  img: myImage
   *  w: 10,
   *  h: 20,
   *  canW: 600,
   *  canH: 600,
   *  rad: 1, // start in middle of canvas due to smallest possible radius
   *  ang: 0, // start at angle 0
   *  ani: {
   *    repeat: true, // repeat animation
   *    die: false,   // don't die after animation is finished
   *    ticks: 5,     // next frame of animation every 5 ticks
   *    map: [[1, 1], [2, 1], [3, 1], [4, 1], [5, 1], [6, 1]]
   *  },
   *  auto: [
   *    {
   *      t: 'arcmove', // perform an arcMove
   *      rad: 200,     // stop "vertical" movement if radius 200 was reached
   *      ang: 45,      // stop "horizontal" movement if angle 45 was reached
   *      dz: 1         // move "vertically" away from center
   *    }
   *  ]
   * })
   *
   * while (gameIsRunning){
   *  mySprite.update().draw(myContext)
   * }
   */
  constructor ({ x = 0, y = 0, ang = 0, rad = 0, img = null, w, h, offx = 0, offy = 0, canW, canH, zoom = 1, rotate = 0, alpha = 1, speed = 0, autoZoom = false, autoRotate = false, ani = null, auto = null, collideable = true, meta = {} }) {
    /**
     * current x-coordinate
     * @type {number}
     * @since 0.1.0
     */
    this.x = x

    /**
     * current y-coordinate
     * @type {number}
     * @since 0.1.0
     */
    this.y = y

    /**
     * current angle in degree (0 - 360);
     * must be valid if calling {@link Sprite#arcMove}
     * @type {number}
     * @since 0.1.0
     */
    this.ang = Utils.validateDegree(ang)

    /**
     * current radius in px;
     * must be valid if calling {@link Sprite#arcMove}
     * @type {number}
     * @since 0.1.0
     */
    this.rad = rad > 0 ? rad : 1

    /**
     * image
     * @type {HTMLImageElement}
     * @since 0.1.0
     */
    this.img = img

    /**
     * width
     * @type {number}
     * @since 0.1.0
     */
    this.w = w

    /**
     * height
     * @type {number}
     * @since 0.1.0
     */
    this.h = h

    /**
     * x-position on image; used if cropping is neccessary
     * @type {number}
     * @since 0.1.0
     */
    this.offx = offx

    /**
     * y-position on image; ; used if cropping is neccessary
     * @type {number}
     * @since 0.1.0
     */
    this.offy = offy

    /**
     * y-coordinate of canvas' center
     * @private
     * @type {number}
     * @since 0.1.0
     */
    this._cx = canW / 2

    /**
     * y-coordinate of canvas' center
     * @private
     * @type {number}
     * @since 0.1.0
     */
    this._cy = canH / 2

    /**
     * zoomfactor
     * @type {number}
     * @since 0.1.0
     */
    this.zoom = zoom

    /**
     * if true, {@link Sprite#zoom} will be adapted to {@link Sprite#rad} when {@link Sprite#arcMove} is called
     * @type{boolean}
     * @since 0.1.0
     */
    this.autoZoom = autoZoom

    /**
     * rotation of {@link Sprite#img} in degree (0 - 360)
     * @type {number}
     * @since 0.1.0
     */
    this.rotate = rotate

    /**
     * if true, {@link Sprite#rotate} will be adapted to {@link Sprite#rad} when {@link Sprite#draw} is called
     * @type{boolean}
     * @since 0.1.0
     */
    this.autoRotate = autoRotate

    /**
     * transparency (0 - 1.0)
     * @type {number}
     * @since 0.1.0
     */
    this.alpha = alpha

    /**
     * velocity
     * @type {number}
     * @since 0.1.0
     */
    this.speed = speed

    /**
     * config-object of animation
     * @type {AnimationConfig}
     * @since 0.2.0
     */
    this.ani = ani
    if (ani) {
      this.ani._t = 0
      this.ani._i = 0
    }

    /**
     * marks if the object is alive
     * @type {boolean}
     * @since 0.2.0
     */
    this.isAlive = true

    /**
     * marks if the object is currently in it's initializing phase
     * @type {boolean}
     * @since 0.3.0
     * @example if (mySprite.isInitializing) { renderProtecitveShield() }
     */
    this.isInitializing = false

    /**
     * events to process automatically when {@link Sprite#update} is called
     * @type {AutomationConfig}
     * @since 0.3.0
     */
    this.auto = auto

    /**
     * a marker for miscellaneous purpose
     * @type {boolean}
     * @since 0.4.8
     * @example mySprite.marked = true; if (mySprite.marked) { doSomethingFancy() } else { skipFancyStuff() }
     */
    this.marked = false

    /**
     * marks if this Sprite is collideable
     * @type {boolean}
     * @since 0.4.9
     */
    this.isCollideable = collideable

    /**
     * container for custom meta data
     * @type {Object}
     * @since 0.5.10
     */
    this.meta = meta

    /**
     * index of current event
     * @private
     * @type {number}
     * @since 0.3.0
     */
    this._eventIndex = 0

    /**
     * initial values
     * @private
     * @type {Object}
     * @property {number} zoom initial zoom
     * @property {number} rad initial radius
     * @property {number} ang initial angle
     * @since 0.1.0
     */
    this._init = {
      zoom: zoom,
      rad: rad,
      ang: ang
    }

    /**
     * timer properties
     * @private
     * @type {Object}
     * @property {Date} s start
     * @property {number} i interval (to be checked against)
     */
    this._tmr = {
      s: 0,
      i: 0
    }

    /**
     * If ang && rad present then one will possibly move the Sprite in a circle and cartesian coordinates have to be set.
     */
    if (ang && rad) { [this.x, this.y] = Utils.posOnArc(ang, rad, this._cx, this._cy, this.w, this.h, this.zoom) }

    /**
     * wireframe vertices in clockwise order
     * @type {Array<vect2D>}
     * @since 0.4.10
     */
    this.wireframe = Utils.computeHitbox(this.x, this.y, this.w, this.h, false, this.zoom) // calc new position first
  }

  /**
   * Move object in x and y direction.
   * @param {number} dx x-direction (-1 = left, 1 = right, 0 = no movement)
   * @param {number} dy y-direction (-1 = up,   1 = down,  0 = no movement)
   * @param {number} [timeElapsed=1] time since method was called last in seconds e.g 0.0145
   * @param {number} [velocity=this.speed] value to override object's speed
   * @since 0.5.2
   * @example player.move(1, 0)
   * --> use if you want to move by a fixed value every 'tick'
   * --> distance (px) modified by fixed speed (px)
   * @example player.move(0, 1, 0.016)
   * --> use if you want to move by a more consistent value
   * --> distance (px) = speed (px / s) * time (s)
   * --> make shure to configure speed as desired number of px to be moved in 1 second
   */
  move (dx, dy, timeElapsed = 1, velocity = this.speed) {
    // do not round values here due to small changes when using timeElapsed
    if ((dx === -1 || dx === 1 || dx === 0) &&
      (dy === -1 || dy === 1 || dy === 0) &&
      (timeElapsed <= 1 && timeElapsed > 0)) {
      this.x += dx * velocity * timeElapsed
      this.y += dy * velocity * timeElapsed
    }
  }

  /**
   * Move object in circles around canvas' center.
   * @param {number} [dxy=0] xy-direction -1 (counter-clockwise) 1 (clockwise)
   * @param {number} [dz=0] z-direction -1 (to center) 1 (away from center)
   * @param {number} [timeElapsed=1] time since method was called last in seconds e.g 0.0145
   * @example player.arcMove(-1) // moves counter-clockwise
   * @example player.arcMove(-1, 0, 0.525) // moves counter-clockwise with use of timeElapsed
   * @example player.arcMove(0, -1) // moves the object towards canvas center
   */
  arcMove (dxy = 0, dz = 0, timeElapsed = 1) {
    if (dz === values.AWAY_FROM_CENTER || dz === values.TO_CENTER) {
      this.rad += this.speed * timeElapsed * dz
      this.rad = this.rad < 0 ? 0 : this.rad
      if (this.autoZoom) {
        this.zoom = (this.rad / this._init.rad) * this._init.zoom
      }
    }

    if (dxy === values.CLOCKW || dxy === values.COUNTER_CLOCKW) {
      this.ang = Utils.validateDegree(this.ang, this.speed * timeElapsed * dxy)
    }

    const p = Utils.posOnArc(this.ang, this.rad, this._cx, this._cy, this.w, this.h, this.zoom)
    this.x = p[0] // [this.x, this.y] = ... doesn't work here! Why?
    this.y = p[1]
  }

  /**
   * Processes a config of type 'alpha'
   * @private
   * @method
   * @param {AlphaConfig} c the config to be processed
   * @since 0.3.0
   */
  _autoAlpha (c) {
    const u = undefined
    const t = this
    if (c._t === u) { c._t = 0 }

    c._t++
    if (c.ticks === u || isNaN(c.ticks) || (c.ticks !== u && c._t >= c.ticks)) {
      t.alpha += c.step
      c._t = 0
    }

    if (t.alpha === c.end || t.alpha >= 1 || t.alpha < 0 || t.alpha.toFixed(4) === c.end.toFixed(4)) {
      /* using toFixed() as fallback in case js decides to do something strange
         at substraction of c.step e.g. 0.6 + (-0.1) = 0.5000000000001 */
      t._eventIndex++
      if (t.alpha < 0) { t.alpha = 0 }
      if (t.alpha > 1) { t.alpha = 1 }
    }
  }

  /**
   * Processes a config of type 'arcmove'
   * @private
   * @method
   * @param {ArcmoveConfig} c the config to be processed
   * @param {number} [timeElapsed=1] time since method was called last in seconds e.g 0.0145
   * @since 0.3.0
   */
  _autoArcmove (c, timeElapsed = 1) {
    if (c.ang !== undefined && c.dxy !== undefined) { // angle
      if (c._a === undefined) {
        if (c.dxy === values.COUNTER_CLOCKW) {
          c._a = this.ang + c.ang < 360 ? this.ang - c.ang : 360 - (c.ang - this.ang)
        } else if (c.dxy === values.CLOCKW) {
          c._a = Math.abs(this.ang - 360) + c.ang // way to east + way to destination
        } else {
          c._a = 0
        }
      }
      c._a -= this.speed * timeElapsed
      if (c._a <= 0) {
        this._nextAnimation()
        if (this.ang !== c.ang) { this.ang = c.ang }
        return // c.ang reached, ignore c.rad
      }
    }

    this.arcMove(c.dxy, c.dz, timeElapsed)

    if (c.rad !== undefined) { // radius
      if ((c.dz === -1 && this.rad <= c.rad) || (c.dz === 1 && this.rad >= c.rad)) {
        this._nextAnimation()
        if (this.rad !== c.rad) { this.rad = c.rad }
        if (this.rad === 0) { this.rad = 1 }
      }
    }
  }

  /**
   * Sets the index for current animation index to the next value
   * @private
   * @method
   * @since 0.5.3
   */
  _nextAnimation () {
    this._eventIndex++
  }

  /**
   * Draws the object to the given canvas.
   * @method
   * @param {CanvasRenderingContext2D} ctx
   * @example player.draw(myCtx)
   * @since 0.1.0
   */
  draw (ctx) {
    const t = this

    ctx.globalAlpha = t.alpha

    if (t.rotate !== 0 || t.autoRotate) {
      // save the context's coordinate system before we screw with it
      ctx.save()
      // move the origin to objects position
      ctx.translate(t.x, t.y)
      // now move across and down half the width and height of the image
      const cx = t.w * t.zoom / 2
      const cy = t.h * t.zoom / 2
      ctx.translate(cx, cy)
      // rotate around this point
      if (t.autoRotate) {
        ctx.rotate(Utils.radians(Utils.validateDegree(t.ang, t.rotate)))
      } else {
        ctx.rotate(Utils.radians(Utils.validateDegree(0, t.rotate)))
      }
      // draw object with it's new destination coordinates dx = -(cx); dy = -(cy)
      ctx.drawImage(t.img, t.offx, t.offy, t.w, t.h, -(cx), -(cy), t.w * t.zoom, t.h * t.zoom)
      // and restore the coordinate system to its default top left origin with no rotation
      ctx.restore()
    } else {
      ctx.drawImage(t.img, t.offx, t.offy, t.w, t.h, t.x, t.y, t.w * t.zoom, t.h * t.zoom)
    }

    ctx.globalAlpha = 1.0
  }

  /**
   * Draws the object's wireframe
   * @method
   * @param {CanvasRenderingContext2D} ctx
   * @param {string} [color='#ff0000'] color of wireframe
   * @example player.drawWF(myCtx, '#aabbcc')
   * @since 4.9.10
   */
  drawWF (ctx, color = '#ff0000') {
    const t = this
    t.wireframe = Utils.computeHitbox(t.x, t.y, t.w, t.h, false, t.zoom)
    ctx.strokeStyle = color
    ctx.beginPath()
    ctx.moveTo(t.wireframe[0].x, t.wireframe[0].y)
    for (let i = 1; i < t.wireframe.length; i++) {
      ctx.lineTo(t.wireframe[i].x, t.wireframe[i].y)
      ctx.moveTo(t.wireframe[i].x, t.wireframe[i].y)
    }
    ctx.lineTo(t.wireframe[0].x, t.wireframe[0].y)
    ctx.stroke()
  }

  /**
   * Animates the object
   * @method
   * @returns {Sprite} self
   * @since 0.2.0
   */
  animate () {
    const t = this
    if (!t.ani) { return }

    const a = t.ani
    if (a._t === a.ticks) {
      a._t = 0
      this.offx = (a.map[a._i][0] - 1) * t.w
      this.offy = (a.map[a._i][1] - 1) * t.h

      if (a._i < a.map.length - 1) {
        a._i++
      } else if (a.repeat) {
        a._i = 0
      } else if (a.die) {
        t.isAlive = false
      }
    } else {
      a._t++
    }
    return t
  }

  /**
   * Processes configured events in squentiel order. Some events are predefined in {@link events}.
   * <p>The following events can be processed.</p>
   * <ul>
   * <li>{@link ArcmoveConfig} :: everything you might need to move Sprite in or on circles</li>
   * <li>{@link SettingConfig} :: with these you can set every public property of Sprite</li>
   * <li>{@link AlphaConfig} :: manage alpha value of Sprite</li>
   * <li>{@link MarkerConfig} :: set {@link Sprite#marked} to true or false
   * <li>{t: 'log' } :: stringify and console.debug Sprite
   * </ul>
   *
   * @method
   * @param {number} [timeElapsed=1] time since last method call; works on triggered arcmove()
   * @returns {Sprite} self
   * @since 0.3.0
   */
  update (timeElapsed = 1) {
    const t = this
    const u = undefined

    if (!t.auto || t._eventIndex === t.auto.length) { return t }

    const event = t.auto[t._eventIndex]
    switch (event.t) {
      case 'arcmove':
        t._autoArcmove(event, timeElapsed)
        return t
      case 'setting':
        t.isInitializing = event.initialize !== u ? event.initialize : t.isInitializing
        t.isAlive = event.alive !== u ? event.alive : t.isAlive
        t.rad = event.rad !== u && event.rad >= 1 ? event.rad : t.rad
        t.ang = event.ang !== u && event.ang !== null ? event.ang : t.ang
        t.rotate = event.rotate !== u && event.rotate !== null ? event.rotate : t.rotate
        t.autoZoom = event.autoZoom === true || event.autoZoom === false ? event.autoZoom : t.autoZoom
        t.autoRotate = event.autoRotate === true || event.autoRotate === false ? event.autoRotate : t.autoRotate
        t.alpha = event.alpha !== u && event.alpha !== null ? event.alpha : t.alpha
        t.isCollideable = event.collideable === true || event.collideable === false ? event.collideable : t.isCollideable
        if (event.time !== u) {
          t.resetTimer()
          t._tmr.i = event.time
        }
        t._eventIndex++
        t._eventIndex = event.index !== u && event.index !== null && event.index < t.auto.length ? event.index : t._eventIndex
        return t
      case 'alpha':
        t._autoAlpha(event) // todo timeElapsed here?
        return t
      case 'mark':
        t.marked = event.marked
        t._eventIndex++
        return t
      case 'log':
        console.debug(JSON.stringify({ _eventIndex: t._eventIndex, sprite: t }))
        t._eventIndex++
        return t
    }
    return t
  }

  /**
   * @function
   * @returns {boolean} true if differnce between timer start and now is greater or equal than set interval
   * @since 0.3.0
   * @example const player = new Sprite({ auto: [paths.settings.autoshot(250)] }); if (player.timeUp()) { do smthing to release a shot; player.resetTimer() }
   */
  timeUp () {
    return this._tmr.i > 0 && Date.now() - this._tmr.s >= this._tmr.i
  }

  /**
   * Resets timer to actual Date.now() and sets new interval if it was given.
   * @method
   * @param {number} [ms] time in millis
   * @since 0.3.0
   */
  resetTimer (ms) {
    this._tmr.s = Date.now()
    if (ms) {
      this._tmr.i = ms
    }
  }

  /**
  * A simple rectcollide test. Zoomfactor of the objects are considered.
  * @function
  * @param {Sprite} candidate the candidate
  * @returns {boolean} true if rects intersect
  * @since 0.4.0
  * @example if (player.collidesWith(enemy)) { ... };
  */
  collidesWith (candidate) {
    const s = {
      w: (this.w * this.zoom) | 0,
      h: (this.h * this.zoom) | 0,
      x: this.x,
      y: this.y
    }
    const c = {
      w: (candidate.w * candidate.zoom) | 0,
      h: (candidate.h * candidate.zoom) | 0,
      x: candidate.x,
      y: candidate.y
    }
    return Utils.collideAABB(s, c)
  }

  /**
   * @function
   * @since 0.4.3
   * @returns {boolean} true if automations are not defined or all entries are processed
   * @example if (player.autoFinished()) { do smth }
   */
  autoFinished () {
    return !this.auto || this._eventIndex === this.auto.length
  }

  /**
   * Restarts the configured automations
   * @function
   * @since 0.4.3
   */
  restartAuto () {
    this._eventIndex = 0
  }

  matchCenter (match) {
    const t = this
    const mcx = Math.round(match.x + match.w / 2 * match.zoom)
    const mcy = Math.round(match.y + match.h / 2 * match.zoom)

    t.x = mcx
    t.y = mcy
    t.rad = match.rad
    t.ang = match.ang

    const cx = Math.round(t.x + t.w / 2 * t.zoom)
    const cy = Math.round(t.y + t.h / 2 * t.zoom)

    t.x = mcx < cx ? Math.round(t.x - Math.abs(mcx - cx)) : Math.round(t.x + Math.abs(mcx - cx))
    t.y = mcy < cy ? Math.round(t.y - Math.abs(mcy - cy)) : Math.round(t.y + Math.abs(mcy - cy))
  }
}

export { Sprite }
