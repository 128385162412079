/* eslint-env browser */
import { GameObject } from './gameobject'
import { Timer } from 'traft-core'

const PART_W = 5
const PART_H = 5
const COL_STEP = 15
const ANG_OFFSET = 2
const RAD_STEP = 10
const OFFSET_TO_PLAYER = 15

const randomRange = (min, max) => {
  return Math.floor(Math.random() * (max - min - 1)) + min
}

const createRandomImage = () => {
  const can = document.createElement('canvas')
  can.width = PART_W
  can.height = PART_H
  const ctx = can.getContext('2d')

  const r = randomRange(1, 255)
  const g = randomRange(1, 255)
  const b = randomRange(1, 255)

  ctx.beginPath()
  for (let i = PART_W; i > 0; i--) {
    ctx.strokeStyle = `rgb(${r + i * COL_STEP},${g + i * COL_STEP},${b + i * COL_STEP},1)`
    ctx.strokeRect(0 + i, 0 + i, PART_W - i * 2, PART_H - i * 2)
  }
  ctx.stroke()

  const img = new Image()
  img.src = can.toDataURL('image/png')

  return img
}

const createConfig = (player, offset, rad, rings, canW, canH, hitpoints) => {
  return {
    name: 'schwurbelparticle',
    ang: player.ang + offset,
    rad: rad,
    img: createRandomImage(),
    w: PART_W,
    h: PART_H,
    canW: canW,
    canH: canH,
    zoom: rad / rings.p,
    speed: player.speed,
    autoRotate: true,
    hitpoints: hitpoints
  }
}

const createParticles = (player, rings, canW, canH, hitpoints) => {
  const particles = []
  const rad = player.rad - OFFSET_TO_PLAYER
  const maxrows = ((rad - rings.a + 2 * RAD_STEP) / RAD_STEP) | 0
  for (let i = 1; i < maxrows; i++) {
    const current = rad - i * RAD_STEP
    particles.push(new GameObject(createConfig(player, -ANG_OFFSET, current, rings, canW, canH, hitpoints)))
    particles.push(new GameObject(createConfig(player, 0, current, rings, canW, canH, hitpoints)))
    particles.push(new GameObject(createConfig(player, ANG_OFFSET, current, rings, canW, canH, hitpoints)))
  }
  return particles
}

/**
 * A realy deadly beam weapon to be attached to player
 */
class Schwurbelstrahl {
  /**
   * @param {GameObject} player player object
   * @param {Object} rings a set of ring-values
   * @param {number} canW canvas width
   * @param {number} canH canvas height
   * @param {number} firingDuration fire for x ms
   * @param {number} cooldownDuration cool down for x ms
   * @param {number} hitpoints
   */
  constructor (player, rings, canW, canH, firingDuration, cooldownDuration, hitpoints) {
    /**
     * container for Schwurbelstrahl's atomics
     * @type {Array<GameObject>}
     */
    this.particles = createParticles(player, rings, canW, canH, hitpoints)

    /**
     * hitpoints of an atomic
     * @type {number}
     */
    this.hitpoints = hitpoints

    /**
     * @type {number}
     */
    this.firingDuration = firingDuration

    /**
     * @type {Timer}
     */
    this.timerFiring = new Timer()

    /**
     * @type {number}
     */
    this.cooldownDuration = cooldownDuration

    /**
     * @type {Timer}
     */
    this.timerCooldown = new Timer(1)

    this.timerCooldown.start() // instant cool down after creation to be ready for first activation

    /**
     * marks whether Schwurbelstrahl is currently active or not
     * @type {boolean}
     */
    this.isActive = false
  };

  /**
   * Moves Schwurbelstrahl in an Sprite.arcMove
   * @param {number} dxy xy-direction -1 (counter-clockwise) 1 (clockwise)
   * @param {number} dz z-direction -1 (to center) 1 (away from center)
   * @param {number} timeElapsed time since method was called last in seconds e.g 0.0145
   */
  arcMove (dxy, dz, timeElapsed) {
    this.particles.forEach(p => p.arcMove(dxy, dz, timeElapsed))
  };

  /**
   * Mandatory call each frame
   * Checks cooldown timer and provides some eye candy if active
   */
  update () {
    if (this.isActive && this.timerFiring.finished()) {
      this.timerCooldown.reset(this.cooldownDuration)
      this.isActive = false
    } else {
      this.particles[this.particles.length - 1].img = this.particles[0].img
      for (let i = 0; i < this.particles.length - 1; i++) {
        this.particles[i].img = this.particles[i + 1].img
      };
    }
  };

  /**
   * Draws all particles to given canvas
   * @param {CanvasRenderingContext2D} ctx
   */
  draw (ctx) {
    this.particles.forEach(p => p.draw(ctx))
  }

  /**
   * Sets all particles to an specific angle
   * @param {number} ang desired angle
   * @example if(player.killed) { schwurbel.realign(player.ang) }
   */
  realign (ang) {
    for (let i = 0; i < this.particles.length - 1; i += 3) {
      this.particles[i].ang = ang - ANG_OFFSET
      this.particles[i + 1].ang = ang
      this.particles[i + 2].ang = ang + ANG_OFFSET
    }
  }

  /**
   * Resets firing timer and sets active if cooldown timer is finished
   */
  fireWhenNotCoolingDown () {
    if (this.timerCooldown.finished()) {
      this.timerFiring.reset(this.firingDuration)
      this.isActive = true
    }
  }
}

export { Schwurbelstrahl }
