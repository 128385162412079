import { GAMEOBJECTS } from '../config/gameobjectsconfig'
import { GameObject } from '../gameobjects/gameobject'
import { addSquad } from '../modules/utils'

const createLevelOne = (world, game) => {
  world.meta = {
    name: 'POLARIS',
    bonus: 1000,
    squads: [],
    music: game.resourceManager.get('stlvl1').howl
  }

  world.background = game.resourceManager.get('bgrnebc').element
  world.backgroundStatic = true
  world.backgroundCentered = true

  world.addNpc(new GameObject(GAMEOBJECTS.item.orb.weaponTwo(game)), 0.5)
  world.addNpc(new GameObject(GAMEOBJECTS.env.astroid.one(game)))
  world.addNpc(new GameObject(GAMEOBJECTS.env.astroid.one(game)), 2.5)
  world.addNpc(new GameObject(GAMEOBJECTS.env.astroid.one(game)), 5)
  addSquad(game, world, 'b1', 0.5, 0.5, 4, 'BLUE ONE', 1000)
  addSquad(game, world, 'b2', 0.5, 0.5, 4, 'BLUE TWO', 2000)
  world.addNpc(new GameObject(GAMEOBJECTS.sat.one(game)), 3)
  addSquad(game, world, 'r1', 7, 0.35, 12, 'RED ONE', 5000)
  addSquad(game, world, 'b3', 15, 0.5, 6, 'BLUE THREE', 4000)
  addSquad(game, world, 'b4', 15, 0.5, 6, 'BLUE FOUR', 3000)

  return world
}

export { createLevelOne }
