import { Utils } from './utils'
import { Sprite } from './sprite'

/**
 * @typedef {object} DelayedObject
 * @property {string} type 'npc' or 'env'
 * @property {number} delay
 * @property {object} event
 * @property {Sprite} obj
 */

class World {
  /**
   * @param {object} config
   * @property {number} w world's width
   * @property {number} h world's heigth
   * @property {number} camW camera's width (visible screen width)
   * @property {number} camH camera's heigth (visible screen heigth)
   * @property {number} [camX=0] x-coordinate of camera's initial position
   * @property {number} [camY=0] y-coordinate of camera's initial position
   * @property {HTMLImageElement} [background=null] background image
   * @property {boolean} [backgroundStatic=false] defines whether camera's movement is applied
   * @property {boolean} [backgroundCentered=false] centers a static background vertically and horizontally
   * @property {Object} [meta={}] a placeholder for custom metadata
   * @since 0.5.0
   */
  constructor ({ w, h, camW, camH, camX = 0, camY = 0, background = null, backgroundStatic = false, backgroundCentered = false, meta = {} }) {
    /**
     * world's width
     * @type {number}
     * @since 0.5.0
     */
    this.w = w

    /**
     * world's height
     * @type {number}
     * @since 0.5.0
     */
    this.h = h

    /**
     * camera (visible and collideable section of the world)
     * @type {object}
     * @property {number} x - x-position in world
     * @property {number} y - y-position in world
     * @property {number} w - width (screenwidth)
     * @property {number} h - height (screenheigth)
     * @since 0.5.0
     */
    this.camera = { x: camX, y: camY, w: camW, h: camH }

    /**
     * environmental objects
     * @type {Array<Sprite>}
     * @since 0.5.0
     */
    this.environmentObjects = []

    /**
     * environmental objects in camerascope, collideable and drawable
     * @type {Array<Sprite>}
     * @since 0.5.0
     */
    this.visibleEnvironmentObjects = []

    /**
     * environment-object wich has collided with the object to test against
     * @type {Sprite}
     * @since 0.5.1
     */
    this.environmentCollisionObject = null

    /**
     * detailed information about environmentCollisionObject
     * @type {AABBInfo}
     * @since 0.5.1
     */
    this.environmentCollisionInfo = null

    /**
     * NPC objects
     * @type {Array<Sprite>}
     * @since 0.5.2
     */
    this.npcObjects = []

    /**
     * NPC objects in camerascope, collideable and drawable
     * @type {Array<Sprite>}
     * @since 0.5.2
     */
    this.visibleNpcObjects = []

    /**
     * NPC objects wich have not spawned yet
     * @type {Array<DelayedObject>}
     * @since 0.5.2
     */
    this.delayedNpcObjects = []

    /**
     * NPC object wich has collided with the object to test against
     * @type {Sprite}
     * @since 0.5.2
     */
    this.npcCollisionObject = null

    /**
     * detailed information about npcCollisionObject
     * @type {AABBInfo}
     * @since 0.5.2
     */
    this.npcCollisionInfo = null

    /**
     * Effect objects e.g. some explosions
     * @type {Sprite}
     * @since 0.5.5
     */
    this.fxObjects = []

    /**
     * Effect objects in camerascope, drawable but NOT collideable
     * @type {Array<Sprite>}
     * @since 0.5.5
     */
    this.visibleFxObjects = []

    /**
     * enemy shots
     * @type {Sprite}
     * @since 0.5.5
     */
    this.enemyShots = []

    /**
     * enemy shots in camerascope, collideable and drawable
    * @type {Array<Sprite>}
    * @since 0.5.5
    */
    this.visibleEnemyShots = []

    /**
     * enemy shot wich has collided with the object to test against
     * @type {Sprite}
     * @since 0.5.5
     */
    this.enemyShotCollisionObject = null

    /**
     * detailed information about enemyShotCollisionObject
     * @type {AABBInfo}
     * @since 0.5.5
     */
    this.enemyShotCollisionInfo = null

    /**
     * player shots
     * @type {Sprite}
     * @since 0.5.6
     */
    this.playerShots = []

    /**
     * player shots in camerascope, collideable and drawable
     * @type {Array<Sprite>}
     * @since 0.5.6
     */
    this.visiblePlayerShots = []

    /**
     * player shot wich has collided with the object to test against
     * @type {Sprite}
     * @since 0.5.6
     */
    this.playerShotCollisionObject = null

    /**
     * detailed information about playerShotCollisionObject
     * @type {AABBInfo}
     * @since 0.5.6
     */
    this.playerShotCollisionInfo = null

    /**
     * player shot that has hit an NPC
     * @type {Sprite}
     * @since 0.5.6
     */
    this.playerShotThatHit = null

    /**
     * background
     * @type {image}
     * @since 0.5.2
     */
    this.background = background

    /**
     * Marks whether camera's movement has effect on background.
     * For a static background, drawing beginns at 0,0 (x,y) if not centered and
     * for a non-static background only the clip in camera-scope is drawn.
     * @type {boolean}
     * @since 0.5.2
     */
    this.backgroundStatic = backgroundStatic

    /**
     * Marks whether a static background is vertically and horizontally centered in viewport (camera)
     * @type {boolean}
     * @since 0.5.8
     */
    this.backgroundCentered = backgroundCentered

    /**
     * a placeholder for custom metadata
     * @type {Object}
     * @since 0.5.3
     */
    this.meta = meta

    /**
     * holds the number how often update() was called
     * @type {number}
     * @since 0.5.2
     * @private
     */
    this._ticks = 0
  }

  /**
   * axis aligned border boundry data
   * @private
   * @param {Sprite} obj
   * @param {boolean} useOffset if true, add camera position to objects coordinates
   * @since 0.5.2
   * @returns {AABBData}
   */
  _AABBData (obj, useOffset) {
    return {
      x: useOffset ? obj.x + this.camera.x : obj.x,
      y: useOffset ? obj.y + this.camera.y : obj.y,
      w: obj.w * obj.zoom,
      h: obj.h * obj.zoom
    }
  }

  /**
   * Spawns delayed objects if the are ready.
   * @private
   * @since 0.5.2
   */
  _spawnNpc () {
    const stillDelayed = []
    for (const candidate of this.delayedNpcObjects) {
      if (candidate.delay && this._ticks >= candidate.delay) {
        this.npcObjects.push(candidate.obj)
      } else if (candidate.event) {
        throw new Error('not implemented yet')
      } else {
        stillDelayed.push(candidate)
      }
    }
    this.delayedNpcObjects = stillDelayed
  }

  /**
   * Checks objects to be in camera scope or not.
   * @private
   * @param {Array<Sprite>} objects the candidates
   * @returns {Array<Sprite>} objects in camara scope
   * @since 0.5.2
   */
  _getVisibleObjects (objects) {
    const visibleObjects = []
    const camera = {
      x: this.camera.x,
      y: this.camera.y,
      w: this.camera.w,
      h: this.camera.h
    }
    for (const o of objects) {
      const candidate = {
        x: o.x,
        y: o.y,
        w: o.w,
        h: o.h
      }
      if (Utils.collideAABB(camera, candidate)) {
        visibleObjects.push(o)
      }
    }
    return visibleObjects
  }

  /**
   * Checks if an alive object collides with an alive source-object(obj.isAlive === true). Breaks at first collision detection.
   * @private
   * @property {Sprite} candidate the candidate
   * @property {boolean} [useOffset=true] if true, camera's position will be added to object's position
   * @param {Array<Sprite>} srcObjArr Array of source objects
   * @returns {Object|null} null if no collision occurs, otherwise { obj: the source object wich collides, objInfo: AABBData of the source object }
   * @since 0.5.2
   */
  _collidesWith (candidate, useOffset, srcObjArr) {
    if (!candidate.isAlive) {
      return null
    }
    const objAABBData = this._AABBData(candidate, useOffset)
    for (let i = 0; i < srcObjArr.length; i++) {
      if (srcObjArr[i].isAlive) {
        const srcObj = srcObjArr[i]
        const srcObjAABBData = this._AABBData(srcObj, false)
        if (Utils.collideAABB(objAABBData, srcObjAABBData)) {
          return {
            obj: srcObj,
            objInfo: Utils.collideAABBInfos(objAABBData, srcObjAABBData)
          }
        }
      }
    };
    return null
  }

  /**
   * Draws a single object to the given canvas
   * @private
   * @param {Sprite} obj
   * @param {CanvasRenderingContext2D} ctx
   * @param {boolean} [drawAlive=false] if true, only alive object will be drawn
   * @since 0.5.2
   */
  _drawObject (obj, ctx, drawAlive = false) {
    if (drawAlive && !obj.isAlive) {
      return false
    } else {
      const cx = this.camera.x
      const cy = this.camera.y
      obj.x -= cx
      obj.y -= cy
      obj.draw(ctx)
      obj.x += cx
      obj.y += cy
    }
  }

  /**
   * Moves camera towards world's top and calls update().
   * @param {number} velocity
   * @returns {World} self
   * @since 0.5.0
   */
  up (velocity) {
    this.camera.y -= Math.abs(velocity)
    if (this.camera.y < 0) { this.camera.y = 0 }
    return this
  }

  /**
   * Moves camera towards world's bottom and calls update().
   * @param {number} velocity
   * @returns {World} self
   * @since 0.5.0
   */
  down (velocity) {
    this.camera.y += Math.abs(velocity)
    if (this.camera.y + this.camera.h > this.h) { this.camera.y = this.h - this.camera.h }
    return this
  }

  /**
   * Moves camera towards world's left border and calls update().
   * @param {number} velocity
   * @returns {World} self
   * @since 0.5.0
   */
  left (velocity) {
    this.camera.x -= Math.abs(velocity)
    if (this.camera.x < 0) { this.camera.x = 0 }
    return this
  }

  /**
   * Moves camera towards world's right border and calls update().
   * @param {number} velocity
   * @returns {World} self
   * @since 0.5.0
   */
  right (velocity) {
    this.camera.x += Math.abs(velocity)
    if (this.camera.x + this.camera.w > this.w) { this.camera.x = this.w - this.camera.w }
    return this
  }

  /**
   * Draws all environmental objects, NPCs, FX & enemy-shots in camera scope wich are alive (obj.isAlive === true) to the given context.
   * @param {CanvasRenderingContext2D} ctx
   * @returns {World} self
   * @since 0.5.0
   */
  draw (ctx) {
    for (const obj of this.visibleEnvironmentObjects) {
      this._drawObject(obj, ctx, true)
    }
    for (const obj of this.visibleNpcObjects) {
      this._drawObject(obj, ctx, true)
    }
    for (const obj of this.visibleFxObjects) {
      this._drawObject(obj, ctx, true)
    }
    for (const obj of this.visibleEnemyShots) {
      this._drawObject(obj, ctx, true)
    }
    for (const obj of this.visiblePlayerShots) {
      this._drawObject(obj, ctx, true)
    }
    return this
  }

  /**
   * Draws the background.
   * @param {CanvasRenderingContext2D} ctx
   * @throws {Error} if background is not configured
   * @returns {World} self
   * @since 0.5.2
   */
  drawBackground (ctx) {
    if (!this.background) {
      throw new Error('background is not configured')
    }
    let parms = null
    const c = this.camera
    const i = this.background
    if (this.backgroundStatic) {
      ctx.drawImage(
        this.background,
        this.backgroundCentered ? (c.w / 2 - i.width / 2) | 0 : 0,
        this.backgroundCentered ? (c.h / 2 - i.height / 2) | 0 : 0
      )
    } else {
      parms = {
        sx: c.x + c.w <= i.width ? c.x : i.width - c.w,
        sy: c.y + c.h <= i.height ? c.y : i.height - c.h,
        sW: c.w,
        sH: c.h,
        dx: 0,
        dy: 0,
        dW: c.w,
        dH: c.h
      }
      ctx.drawImage(this.background, parms.sx, parms.sy, parms.sW, parms.sH, parms.dx, parms.dy, parms.dW, parms.dH)
    }
    // todo make parms testable without next line
    this._bgrParms = parms // only used for testing this method
    return this
  }

  /**
   * Add an single environment-object to the world.
   * @param {Sprite} obj
   * @returns {World} self
   * @since 0.5.0
   */
  addEnvironmentObject (obj) {
    this.environmentObjects.push(obj)
    return this
  }

  /**
   * Add a single FX object to the world.
   * @param {Sprite} obj
   * @returns {World} self
   * @since 0.5.5
   */
  addFx (obj) {
    this.fxObjects.push(obj)
    return this
  }

  /**
   * Add a single enemy-shot to the world.
   * @param {Sprite} shot
   * @returns {World} self
   * @since 0.5.5
   */
  addEnemyShot (shot) {
    this.enemyShots.push(shot)
    return this
  }

  /**
   * Add a single player-shot to the world.
   * @param {Sprite} shot
   * @returns {World} self
   * @since 0.5.6
   */
  addPlayerShot (shot) {
    this.playerShots.push(shot)
    return this
  }

  /**
   * Add an NPC object to the world
   * @param {Sprite} obj
   * @param {number} [delay=0] ticks or time (in ms) to wait before spawning obj, overrides event!
   * @param {object} [event=null] event to wait for to spawn obj
   * @returns {World|boolean} self if NPC was added, else false
   * @example myWorld.addNpc(mySprite, 20)
   * @example myWorld.addNpc(mySprite, null, myEvent)
   * @example myWorld.addNpc(mySprite, 20, myEvent) // myEvent will be ignored
   * @since 0.5.2
   */
  addNpc (obj, delay = 0, event = null) {
    if (obj instanceof Sprite) {
      if (delay || event) {
        this.delayedNpcObjects.push({
          type: 'npc',
          delay: delay,
          event: delay ? null : event,
          obj: obj
        })
      } else {
        this.npcObjects.push(obj)
        return this
      }
    } else {
      return false
    }
  }

  /**
   * Checks if an object collides with an alive (obj.isAlive === true) environment-object in camera scope.
   * Breaks at first collision detection.
   * @property {Sprite} obj
   * @property {boolean} [useOffset=true] if true, camera's position will be added to object's position
   * @returns {boolean} true if a collision happens
   * @since 0.5.0
   */
  environmentCollidesWith (obj, useOffset = true) {
    const result = this._collidesWith(obj, useOffset, this.visibleEnvironmentObjects)
    if (result) {
      this.environmentCollisionObject = result.obj
      this.environmentCollisionInfo = result.objInfo
      return true
    } else {
      this.environmentCollisionObject = null
      this.environmentCollisionInfo = null
      return false
    }
  }

  /**
   * Checks if an object collides with an alive (obj.isAlive === true) NPC in camera scope.
   * Breaks at first collision detection.
   * @property {Sprite} obj
   * @property {boolean} [useOffset=true] if true, camera's position will be added to object's position
   * @returns {boolean} true if a collision happens
   * @since 0.5.2
   */
  npcCollidesWith (obj, useOffset = true) {
    const result = this._collidesWith(obj, useOffset, this.visibleNpcObjects)
    if (result) {
      this.npcCollisionObject = result.obj
      this.npcCollisionInfo = result.objInfo
      return true
    } else {
      this.npcCollisionObject = null
      this.npcCollisionInfo = null
      return false
    }
  }

  /**
   * Checks if an object collides with an alive (obj.isAlive === true) enemy shot in camera scope.
   * Breaks at first collision detection.
   * @property {Sprite} obj
   * @property {boolean} [useOffset=true] if true, camera's position will be added to object's position
   * @returns {boolean} true if a collision happens
   * @since 0.5.5
   */
  enemyShotsCollidesWith (obj, useOffset = true) {
    const result = this._collidesWith(obj, useOffset, this.visibleEnemyShots)
    if (result) {
      this.enemyShotCollisionObject = result.obj
      this.enemyShotCollisionInfo = result.objInfo
      return true
    } else {
      this.enemyShotCollisionObject = null
      this.enemyShotCollisionInfo = null
      return false
    }
  }

  /**
   * Checks if one of visible player shots collides with an alive (obj.isAlive === true) NPC object in camera scope.
   * Breaks at first collision detection.
   * @property {boolean} [useOffset=true] if true, camera's position will be added to object's position
   * @returns {boolean} true if a collision happens
   * @since 0.5.6
   */
  playerShotCollidesWithNpc (useOffset = true) {
    for (const shot of this.visiblePlayerShots) {
      const result = this._collidesWith(shot, useOffset, this.visibleNpcObjects)
      if (result) {
        this.playerShotCollisionObject = result.obj
        this.playerShotCollisionInfo = result.objInfo
        this.playerShotThatHit = shot
        return true
      }
    }
    this.playerShotCollisionObject = null
    this.playerShotCollisionInfo = null
    this.playerShotThatHit = null
    return false
  }

  /**
   * Performs all necessary updates. Must be called in each frame!
   * If objects where added with delay to spawn measured in time it is recommended
   * to call with parameter timeElapsed.
   * @property {number} [timeElapsed] elapsed time since update() was called last.
   * @returns {World} self
   * @since 0.5.2
   * @example while(myGameLoopRuns) { myWorld.update(); myWorld.draw() }
   * @example while(myGameLoopRuns) { myWorld.update().draw(); }
   * @example while(myGameLoopRuns) { myWorld.update(60/1000).draw(); }
   */
  update (timeElapsed) {
    if (timeElapsed) {
      this._ticks += timeElapsed
    } else {
      this._ticks++
    }
    this._spawnNpc()
    this.visibleEnvironmentObjects = this._getVisibleObjects(this.environmentObjects)
    this.visibleNpcObjects = this._getVisibleObjects(this.npcObjects)
    this.visibleFxObjects = this._getVisibleObjects(this.fxObjects)
    this.visibleEnemyShots = this._getVisibleObjects(this.enemyShots)
    this.visiblePlayerShots = this._getVisibleObjects(this.playerShots)
    return this
  }
}

export { World }
