const VERSION = '0.1.6'

const TEXTS = {
  scroller: {
    startscreen: '         MONIMAL FARMINGS INC. PRESENTS ANOTHER HOMAGE TO THE 8 BIT TIMES      CODE  TRAFT   SFX  TRAFT   MUSIC  TRAFT   GFX  TRAFT * PUDMAN * PAUL WORTMANN * SKORPIO * STUMPYSTRUST * RAWDANITSU * PHAELAX * JOSEPH CROWN    PRESS C TO SHOW FULL CREDITS'
  },
  gameOver: 'GAME OVER',
  gameFinished: 'HOORAY! YOU SAVED US ALL',
  hud: {
    score: 'SCORE  ',
    weapon: 'WEAPON ',
    fps: 'FPS ',
    version: VERSION,
    stagePre: 'STAGE ',
    stageSuf: ' CLEARED',
    bodycount: 'KILLED ',
    ecaped: 'ESCAPED ',
    bonus: 'BONUS '
  },
  start: {
    rowa: '* CHANGE DIRECTION *',
    rowb: 'TAP OR ARROW L + R',
    rowc: '* ACTIVATE SCHWURBELSTRAHL *',
    rowd: 'SWIPE RIGHT OR ARROW UP',
    rowe: 'TAP OR SPACE TO START'
  }
}

export { TEXTS }
