
/**
 * validate a number if it is a valid degree
 * @private
 * @function
 * @since 0.4.2
 * @param {number} a the candidate
 * @returns {number|null} a valid degree or null if not valid
 */
const _ang = (a) => {
  return a >= 0 && a <= 360 ? a : null
}

/**
 * validate a number to be a valid 'rad'-property for sprite
 * @private
 * @function
 * @since 0.4.2
 * @param {number} r the candidate
 * @returns {number|null} given number if valid, otherwise null
 */
const _rad = (r) => {
  return r > 0 ? r : null
}

/**
 * validate a number to be a valid 'alpha' value
 * @private
 * @function
 * @since 0.4.2
 * @param {number} a the candidate
 * @returns {number} candidate if valid, 0 if < 0, 1 if > 1
 */
const _alpha = (a) => {
  return a < 0 ? 0 : (a > 1 ? 1 : a)
}

/**
 * @namespace
 */
const events = {}

/**
 * @namespace
 */
events.arcmove = {}

/**
 * @namespace
 */
events.settings = {}

/**
 * @namespace
 */
events.alpha = {}

const _s = 'setting'
const _a = 'alpha'
const _arc = 'arcmove'

/**
 * marks the object as approching
 * @function
 * @since 0.3.0
 * @returns {SettingConfig}
 */
events.settings.initialize = function () {
  return {
    t: _s,
    initialize: true
  }
}

/**
 * marks the object as approaching ended and ready
 * @function
 * @since 0.3.0
 * @returns {SettingConfig}
 */
events.settings.ready = function () {
  return {
    t: _s,
    initialize: false
  }
}

/**
 * lets the object die
 * @function
 * @since 0.3.0
 * @returns {SettingConfig}
 */
events.settings.die = function () {
  return {
    t: _s,
    alive: false
  }
}

/**
 * sets timer interval for autoshot
 * @param {number} ms time in millis
 * @since 0.3.0
 * @returns {SettingConfig}
 * @example const player = new Sprite({ auto: [paths.settings.autoshot(250)] }); if (player.timeUp()) { do smthing to release a shot; player.resetTimer() }
 */
events.settings.autoshot = function (ms) {
  return {
    t: _s,
    time: ms
  }
}

/**
 * sets value for rad
 * @param {number} r radius in px; minimum is 1
 * @since 0.4.1
 * @returns {SettingConfig}
 * @example const player = new Sprite({ auto: [paths.settings.rad(2)] }); player.update() }
 */
events.settings.rad = function (r) {
  return {
    t: _s,
    rad: _rad(r)
  }
}

/**
 * sets value for ang
 * @param {number} a angle in degree (0 - 360)
 * @since 0.4.1
 * @returns {SettingConfig}
 * @example const player = new Sprite({ auto: [paths.settings.ang(2)] }); player.update() }
 */
events.settings.ang = function (a) {
  return {
    t: _s,
    ang: _ang(a)
  }
}

/**
 * sets value for rotate
 * @param {number} a rotation in degree (0 - 360)
 * @since 0.4.1
 * @returns {SettingConfig}
 * @example const player = new Sprite({ auto: [paths.settings.rotate(2)] }); player.update() }
 */
events.settings.rotate = function (r) {
  return {
    t: _s,
    rotate: _ang(r)
  }
}

/**
 * sets value for autoZoom
 * @param {boolean} [b=true]
 * @since 0.4.1
 * @returns {SettingConfig}
 * @example const player = new Sprite({ auto: [paths.settings.autoZoom()] }); player.update() }
 */
events.settings.autoZoom = function (b) {
  const r = b === true || b === false ? b : null
  return {
    t: _s,
    autoZoom: r === null ? true : r
  }
}

/**
 * sets value for autoRotate
 * @param {boolean} [b=true]
 * @since 0.4.1
 * @returns {SettingConfig}
 * @example const player = new Sprite({ auto: [paths.settings.autoRotate()] }); player.update() }
 */
events.settings.autoRotate = function (b) {
  const r = b === true || b === false ? b : null
  return {
    t: _s,
    autoRotate: r === null ? true : r
  }
}

/**
 * sets value for alpha
 * @function
 * @since 0.4.2
 * @param {number} a a value between 0 and 1
 * @returns {SettingConfig}
 * @example const player = new Sprite({ alpha: 1.0, auto: [paths.settings.alpha(0.5)] });
 */
events.settings.alpha = function (a) {
  return {
    t: _s,
    alpha: _alpha(a)
  }
}

/**
 * restarts the automation
 * @function
 * @since 0.4.9
 * @param {number} [aix=0] index of animation
 * @returns {SettingConfig}
 * @example const enemy = new Sprite({ auto: [some other configs, paths.settings.restart()] })
 * @example const enemy = new Sprite({ auto: [some other configs, paths.settings.restart(5)] })
 */
events.settings.restart = function (eventIndex = 0) {
  return { t: _s, index: eventIndex < 0 || eventIndex === null || eventIndex === undefined ? 0 : eventIndex }
}

/**
 * sets value for collideable
 * @param {boolean} [b=true]
 * @since 0.4.9
 * @returns {SettingConfig}
 * @example const player = new Sprite({ auto: [paths.settings.collideable()] }) }
 * @example const player = new Sprite({ auto: [paths.settings.collideable(false)] }) }
 */
events.settings.collideable = function (b) {
  const r = b === true || b === false ? b : null
  return {
    t: _s,
    collideable: r === null ? true : r
  }
}

/**
 * moves object in an inifinite circle
 * @function
 * @since 0.3.0
 * @param {number} dxy xy-direction -1 (counter-clockwise) 1 (clockwise)
 * @returns {ArcmoveConfig}
 * @example const player = new Sprite({ auto: [paths.arcmove.infiniteCircle(values.CLOCKW)] }); while (true) { player.update(); player.draw() }
 */
events.arcmove.infiniteCircle = function (dxy) {
  return {
    t: _arc,
    dxy: (dxy === 1 || dxy === -1) ? dxy : 0
  }
}

/**
 * fades an object in
 * @function
 * @since 0.3.0
 * @param {number} end desired value
 * @param {number} step value to add
 * @param {number} [ticks] do addition when ticks' value is reached
 * @returns {AlphaConfig}
 * @example const player = new Sprite({ alpha: 0.1, auto: [paths.alpha.fadein(0.5, 0.1, 2)] }); while (true) { player.update(); player.draw() }
 */
events.alpha.fadein = function (end, step, ticks) {
  return {
    t: _a,
    end: _alpha(end),
    step: Math.abs(step),
    ticks: Math.abs(ticks)
  }
}

/**
 * fades an object out
 * @function
 * @since 0.3.0
 * @param {number} end desired value
 * @param {number} step value to subtract
 * @param {number} [ticks] decrease when ticks' value is reached
 * @returns {AlphaConfig}
 * @example const player = new Sprite({ alpha: 1.0, auto: [paths.alpha.fadeout(0.5, 0.1, 2)] }); while (true) { player.update(); player.draw() }
 */
events.alpha.fadeout = function (end, step, ticks) {
  const c = events.alpha.fadein(end, step, ticks)
  c.step = c.step * -1
  return c
}

/**
 * sets value for alpha
 * @function
 * @since 0.4.2
 * @param {number} a a value between 0 and 1
 * @returns {SettingConfig}
 * @example const player = new Sprite({ alpha: 1.0, auto: [paths.alpha.set(0.5)] });
 */
events.alpha.set = function (a) {
  return events.settings.alpha(a)
}

/**
 * sets custom marker
 * @function
 * @since 0.4.8
 * @param {boolean} [b=true] desired value
 * @returns {MarkerConfig}
 * @example const player = new Sprite({ auto: [paths.mark()] });
 * @example const player = new Sprite({ auto: [paths.mark(false)] });
 */
events.mark = function (b = true) {
  return { t: 'mark', marked: b }
}

export { events }
