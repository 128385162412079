import { World } from 'traft-core'
import { createLevelOne } from '../scenes/one'
import { createLevelTwo } from '../scenes/two'
import { createLevelThree } from '../scenes/three'

const createLevel = (num, game) => {
  const world = initWorld(game)
  switch (num) {
    case 1:
      return createLevelOne(world, game)
    case 2:
      return createLevelTwo(world, game)
    case 3:
      return createLevelThree(world, game)
    default:
      throw new Error(`level '${num}' not present!`)
  }
}

const initWorld = (game) => {
  return new World({
    w: game.w,
    h: game.h,
    camW: game.w,
    camH: game.h,
    camX: 0,
    camY: 0
  })
}

export { createLevel }
