import { ResourceManager } from '../modules/resourcemanager'

const CANVAS_ID = 'schwurblergame'

const STATES = {
  loading: 0,
  init: 1,
  startscreen: 2,
  running: 3,
  prepareNextLevel: 4,
  gameOver: 5,
  spawnNewShip: 6,
  gameFinished: 7
}

const COMMANDS = {
  arcMoveCW: 0,
  arcMoveCounterCW: 1,
  activateSchwurbel: 2
}

const initGame = (canW, canH) => {
  const game = {
    w: canW,
    h: canH,
    state: STATES.loading,
    player: {
      state: null
    },
    currentCommand: null,
    previousCommand: null,
    currentLevel: null,
    level: null,
    maxLevel: 3,
    score: 0,
    lives: 3,
    speed: {
      npc: 1,
      npcShot: 1,
      player: 1,
      playerShot: 1,
      env: 1,
      item: 1
    },
    times: {
      showLevelFinishedScr: 3000,
      splash: 3
    },
    schwurbel: {
      timer: {
        firing: 5000,
        cooldown: 8000
      },
      init: {
        hitpoints: 5
      }
    },
    themeMusicSid: null
  }

  game.ratio = (game.w / 600 + game.h / 600) / 2

  game.resourceManager = new ResourceManager()

  return game
}

export { COMMANDS, STATES, CANVAS_ID, initGame }
