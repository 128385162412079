import { World, Sprite } from 'traft-core'
import { GAMEOBJECTS } from '../config/gameobjectsconfig'
import { GameObject } from '../gameobjects/gameobject'

import soundtrackSrc from '../resources/levelone.ogg'
import backgroundSrc from '../resources/nebula3.png'

const createStartScreen = (game) => {
  // lazy load resources for level on
  game.resourceManager.add({
    id: 'stlvl1',
    type: game.resourceManager.TYPE.howl,
    src: soundtrackSrc
  })

  game.resourceManager.add({
    id: 'bgrnebc',
    type: game.resourceManager.TYPE.image,
    src: backgroundSrc
  })

  const screen = new World({
    w: game.w,
    h: game.h,
    camW: game.w,
    camH: game.h,
    camX: 0,
    camY: 0
  })

  screen.addEnvironmentObject(new Sprite({
    x: game.w / 2 - (300 * game.ratio) / 2,
    y: 50 * game.ratio,
    w: 300,
    h: 134,
    img: game.resourceManager.get('title').element,
    name: 'title',
    zoom: 1 * game.ratio
  }))

  screen.addNpc(new GameObject(GAMEOBJECTS.sat.one(game)))

  return screen
}

export { createStartScreen }
